





export const activity =[

    " مواد بناء  ",
    " ادوات كهرباء  ",
    " طلاء وبلاط  ",
    " بلاستيك  ",
]


