
import React from "react";



const Loader = () => {
	

 


	return (

<div style={{
				position: "fixed",
				zIndex:1000,
				top: 0,
				left: 0,
               
				width: "100%",
				height: "100%",
				background: "rgba(0, 0, 0, 0.2)",
			backdropFilter:'blur(8px)',
            alignItems: 'center',
            display: "flex",
            justifyContent:'center'
  

			}}>

<div id="container">
  <label class="loading-title">Loading ...</label>
  <span class="loading-circle sp1">
    <span class="loading-circle sp2">
      <span class="loading-circle sp3"></span>
    </span>
  </span>
</div>

</div>	
	
	);
};

export default Loader;
