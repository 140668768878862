import React, { forwardRef, useEffect, useRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useAuthUser } from "react-auth-kit";
import { MdClose, MdMoreVert } from "react-icons/md";
import { activity } from "../data";
import Modal from "../../Modal";



const Images = ({loading, stop}) => {

  
     
    const auth = useAuthUser()

    const key = auth().key; 
      
    const username = auth().username;

  useEffect( () => {
   getData()
  
   },[]);


  
  
      const [data, setData] = useState({
         alt:'',
          home:'',
          category:'',
        });
        
        
const [updatedata, setUpdateData] = useState({
  id:'',
  alt:'',
  alt_:'',
          home:'',
          category:'',
 });
 


 const onChangeValue1 = (e) => {

   
  if(e.target.name === 'category' && e.target.value ==='0' ){
    setUpdateData({ ...updatedata, category:'0' ,home:'1'})
  }else{

    setUpdateData({
      ...updatedata,
      [e.target.name]:e.target.value
    });
  }
         
 } 
 

  
  
        const onChangeValue = (e) => {
        
  
  if(e.target.name === 'category' && e.target.value ==='0' ){
    setData({ ...data, category:'0' ,home:'1'})
  }else{

    setData({
      ...data,
      [e.target.name]:e.target.value
    });
  }
         
          
        } 
        
  
        
        const [agents, setAgents] = useState([])
  
        const getData = async () => {
           
   loading()
     
   try {
    
     
    
   
     
     axios.get(`https://admin.al-hubaishi.com/Api/Images.php?key=${key}&username=${username}`)
     .then(res => {
       if(res.data.success === true){
 
     
        setAgents(res.data.datalist.images)
    
      stop()
          
       }else{
         

         toast.error(`${res.data.msg}`,{duration: 3000,},)
         stop()
          
            
           }  
     
 
     })
  } catch (error) { 
   stop()

   throw error;}      
   

        }
  
  
  
  
        const Add = async(e) => {
      
            e.preventDefault();
         
    
    
            loading()
    
         
     
            try {
            
            
              var formData = new FormData();
              formData.append("username",username); 
              formData.append("key",key); 
           
            
              formData.append("alt",data.alt);
              formData.append("photo",file);
              formData.append("category",data.category);
              formData.append("homescreen",data.home);
            
              
              axios.post(`https://admin.al-hubaishi.com/Api/add_image.php`, formData)
              .then(res => {
                
          
    
    
    
        if(res.data.success === true){
         setData({
            alt:'',
            home:'',
            category:'',
         })
         setFile('')
         handleReset()
              stop()
              getData()
  
            toast.success(`${res.data.msg}`,{duration: 3000,},)
       
        
        
           
    
        }else{
          stop()
            toast.error(`${res.data.msg}`,{duration: 3000,},)
        }
    
    
    
       
            
              })
           } catch (error) { 
        stop()
        toast.error(`${error}`,{duration: 3000,},)
        throw error;}      
          
      
          
    
    
    
    
        
    
    
    
        }
    
     
  
  
      const update = async(e) => {
      
          e.preventDefault();
       
  handleClose()
  
          loading()
  
       
  
          try {
          
          
            var formData = new FormData();
            formData.append("username",username); 
            formData.append("key",key); 
         
      
            formData.append("id",updatedata.id);
            formData.append("alt",updatedata.alt);
    
            formData.append("category",updatedata.category);
            formData.append("homescreen",updatedata.home);
          
            
            axios.post(`https://admin.al-hubaishi.com/Api/Edit_images.php`, formData)
            .then(res => {
              
        
  
  
  
      if(res.data.success === true){
       
            stop()
            getData()

          toast.success(`${res.data.msg}`,{duration: 3000,},)
     
      
      
         
  
      }else{
        stop()
          toast.error(`${res.data.msg}`,{duration: 3000,},)
      }
  
  
  
     
          
            })
         } catch (error) { 
      stop()
      toast.error(`${error}`,{duration: 3000,},)
      throw error;}      
        
    
        
  
  
  
  
      
  
  
  
      }
  
  
  
  
  

  
      const update_img = async(e) => {
      
        e.preventDefault();
     
        handleClose()

        loading()

     

        try {
        
        
          var formData = new FormData();
          formData.append("username",username); 
          formData.append("key",key); 
       
    
          formData.append("id",updatedata.id);
          formData.append("alt",updatedata.alt_);
          formData.append("photo",file);
       
        
          
          axios.post(`https://admin.al-hubaishi.com/Api/Edit_img.php`, formData)
          .then(res => {
            
      



    if(res.data.success === true){
     
          stop()
          getData()

        toast.success(`${res.data.msg}`,{duration: 3000,},)
   
    
    
       

    }else{
      stop()
        toast.error(`${res.data.msg}`,{duration: 3000,},)
    }



   
        
          })
       } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
      
  
      




    



    }





  
    const del = async() => {
      
   
handleClose()

      loading()

   

      try {
      
      
        var formData = new FormData();
        formData.append("username",username); 
        formData.append("key",key); 
     
  
        formData.append("id",updatedata.id);
     
      
        
        axios.post(`https://admin.al-hubaishi.com/Api/delete_images.php`, formData)
        .then(res => {
          
    



  if(res.data.success === true){
   
        stop()
        getData()

      toast.success(`${res.data.msg}`,{duration: 3000,},)
 
  
  
     

  }else{
    stop()
      toast.error(`${res.data.msg}`,{duration: 3000,},)
  }



 
      
        })
     } catch (error) { 
  stop()
  toast.error(`${error}`,{duration: 3000,},)
  throw error;}      
    

    




  



  }

  
          
  
  
      const [file,setFile] = useState("");
        
      const setimgfile = (e)=>{
        setFile(e.target.files[0])
      }
      
      
const inputFile = useRef(null);


const handleReset = () => {
    if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
    }
};
  
  
   
const [show, setShow] = React.useState(-1);  
  
  


const [fid, setFid] = React.useState(0);  
  

const [index, setIndex] = React.useState(0);  
  

      const [open, setOpen] = React.useState(false);
 
      const handleClose = () => {
        setIndex(0)
        setFid(0)
          setOpen(false);

          setUpdateData({
            id:'',
            alt:'',
     src:'',
     category:'',
     home:'',
    })
    setShow(-1)
      };
      
      const handleOpen = () => {
          setOpen(true);
      };
      
  


return (
 <div className="w-full h-screem pt-[80px] ">


<div className="mb-[10px]
">



  <form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={Add}>
 
 



 

<p className="title1"> الصور  </p>

<label  className="">
<input className="input" id="img" name="img" ref={inputFile} onChange={setimgfile} accept="image/*" type="file" placeholder="" required/>
        <span className=""> الصورة </span>
    </label> 

<div  dir='rtl' className="flex">


    <label  className="w-[50%] m-1 ">
    <select required placeholder="" value={data.category} name="category" id="category" onChange={onChangeValue} className="input">
            <option value=''></option>
            
            <option  value='0'>الرئيسية </option>
            <option  value='1'>مواد بناء</option>
                <option  value='2'>ادوات كهرباء</option>
                <option  value='3'>طلاء وبلاط</option>
                <option  value='4'>بلاستيك</option>
               
                
       
              
            </select>
            <span className=""> النوع </span>
    </label> 

    <label  className="w-[50%] m-1 ">
    <select  required placeholder="" value={data.category === '0' ? '1' : data.home} name="home" id="home"  onChange={onChangeValue} className="input">
  {  data.category === '0' ?
    <option  value='1'>نعم</option>
    :<>

 
            <option value=''></option>
            

            <option  value='0'>لا</option>
            <option  value='1'>نعم</option>
            </>}
       
              
            </select>
            <span className=""> الصقحة الرئيسية </span>
    </label> 

</div>

<hr />
    
    <label id="" className=" ">
        <input dir="rtl" required className="input" id="alt" name="alt"  value={data.alt} onChange={onChangeValue} placeholder=""  />
        <span className=""> alt </span>
    </label> 
 
    
    <button className="submit">أضافة</button>

   
</form>








   

</div>



<hr></hr>
  <div  className= {`flex mt-2  `}>



<div dir="rtl" style={{placeContent:"center" , verticalAlign:'middle'}}  className=" m-auto flex max-w-[1000px] w-[90%] mb-2 p-2 rounded bg-gray-300  h-[40px] "> 






<div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[20%]">
   <div className="font-bold text-[15px]"> 
   <p style={{wordBreak:'break-word'}}>الصورة</p>
   </div>
 </div>   






 <div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[35%] ">
   <div className="font-bold text-[15px]"> 
   <p style={{wordBreak:'break-word'}}>alt</p>
   </div>
 </div>   






 <div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[20%] ">
   <div className="font-bold text-[15px]"> 
   <p style={{wordBreak:'break-word'}}>النشاط</p>
   </div>
 </div>   




 <div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[15%] ">
   <div className="font-bold text-[15px]"> 
   <p style={{}}>الرئيسية</p>
   </div>
 </div>   


 <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[10%] ">

  <p style={{wordBreak:'break-word'}}>...</p>

</div>




   </div>
</div>
<hr></hr>
  {agents.map((item,i) => 

<>
<div  className= {`flex mt-2  `}>



 <div dir="rtl" style={{placeContent:"center" , verticalAlign:'middle'}}  
 
 className=  {` m-auto flex max-w-[1000px] w-[90%] mb-2 p-2 rounded border-[2px] min-h-[100px]`}> 



 

  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[20%] ">
   <div className="flex ">
    <img className="m-auto rounded" src={`https://al-hubaishi.com/pic/${item.src}`} alt='' height="70px" width="70px" fill="#ffffff" />
   </div>
  </div>


 



  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[35%] ">
    <div className="font-bold text-[15px] "> 
      <p style={{wordBreak:'break-word'}}>{item.alt}</p>
    </div>
  </div>




  <div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[30%]  ">
    <div className="font-bold text-[15px]"> 
    <p style={{wordBreak:'break-word'}}>{Number(item.category) > 0 ? activity[Number(item.category)-1] :'الرئيسية'}</p>
    </div>
  </div>   


  <div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[15%]  ">
    <div className="font-bold text-[15px]"> 
    <p style={{wordBreak:'break-word'}}>{Number(item.homescreen) > 0  ? "نعم" : 'لا'}</p>
    </div>
  </div>   


  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[10%] ">

  <button onClick={()=>{



    if(show===i){
        setShow(-1)
        setUpdateData({
        id:'',
        alt:'',
 src:'',
 category:'',
 home:'',
})
   
    }else{


      setUpdateData({
        id:item.id,
        alt:item.alt,
        alt_:item.alt,
 src:item.src,
 category:item.category,
 home:item.homescreen,
})

      setIndex(i)
        setShow(i)
    }
    
  }} >
            {show===i ?  <MdClose color="grey" size={30} />
            :<MdMoreVert color="grey" size={30} />}
            </button>
          { show===i ? <div className="dropdown">
  <div className="dropdown-content">

<button className="p-2 bg-[#dbdbdb] w-[120px] rounded hover:opacity-70 m-1" onClick={()=>{
handleOpen()
}} >
        تعديل
          </button>
          <br></br>
          <button className={`text-red-500 p-2 bg-[#dbdbdb] w-[120px] rounded hover:opacity-70 m-1`} onClick={()=>{



confirmAlert({
        title: 'Confirm to Submit',
        message:` هل أنت متأكد ، تريد حذف صورة  `,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {

del()
}
          },
          {
            label: 'No',
            onClick: () => {  setShow(-1);}
          }
        ]
      });

}} >
       حذف
          </button>
</div>


  
</div>:''}
 
 </div>




    </div>
</div>

<hr />
</>

)}




<Modal isOpen={open} onClose={handleClose}>



<div className="flex" >
  <img className="m-auto rounded" src={`https://al-hubaishi.com/pic/${updatedata.src}`} alt="" width={'200px'} />
</div>

<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={update_img}>

<label  className="">
 <input className="input" id="img" name="img" ref={inputFile} onChange={setimgfile} accept="image/*" type="file" placeholder="" required/>
         <span className=""> الصورة </span>
     </label> 
 
     <button className="submit">تحديث</button>
</form>


<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={update}>
 
 <p className="title1"> الصور  </p>
 
 <div  dir='rtl' className="flex">
 
 
     <label  className="w-[50%] m-1 ">
     <select required placeholder="" value={updatedata.category} name="category" id="category" onChange={onChangeValue1} className="input">
             <option value=''></option>
             
             <option  value='0'>الرئيسية </option>
             <option  value='1'>مواد بناء</option>
                 <option  value='2'>ادوات كهرباء</option>
                 <option  value='3'>طلاء وبلاط</option>
                 <option  value='4'>بلاستيك</option>
                
                 
        
               
             </select>
             <span className=""> النوع </span>
     </label> 
 
     <label  className="w-[50%] m-1 ">
     <select  required placeholder="" value={updatedata.category === '0' ? '1' : updatedata.home} name="home" id="home"  onChange={onChangeValue1} className="input">
  {  updatedata.category === '0' ?
    <option  value='1'>نعم</option>
    :<>

 
            <option value=''></option>
            

            <option  value='0'>لا</option>
            <option  value='1'>نعم</option>
            </>}
               
             </select>
             <span className=""> الصقحة الرئيسية </span>
     </label> 
 
 </div>
 
 <hr />
     
     <label id="" className=" ">
         <input dir="rtl" required className="input" id="alt" name="alt"  value={updatedata.alt} onChange={onChangeValue1} placeholder=""  />
         <span className=""> alt </span>
     </label> 
  
     
     <button className="submit">حفظ</button>
 
    
 </form>
</Modal>








    </div>






);
};

export default Images;
