import React, { forwardRef, useEffect, useRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useAuthUser } from "react-auth-kit";
import { activity } from "../data";



const Activity = ({loading, stop}) => {

  
     
    const auth = useAuthUser()

    const key = auth().key; 
      
    const username = auth().username;

  useEffect( () => {
    getData()
  
   },[]);


  
  
      const [data, setData] = useState({
          about:'',
          subtext:'',
          about_:'',
          subtext_:'',
        });
        
        
  
  
        const onChangeValue = (e) => {
          setData({
            ...data,
            [e.target.name]:e.target.value
          });
  
  
        
         
          
        } 
        
  
        const [activities, setActivities] = useState([])
  
  
        const getData = async () => {
           
   loading()
     
   try {
    
   
    
   
     
     axios.get(`https://admin.al-hubaishi.com/Api/Activity.php?key=${key}&username=${username}`)
     .then(res => {
       if(res.data.success === true){
 
        setActivities(res.data.datalist.constants)
    
    
      stop()
          
       }else{
         

         toast.error(`${res.data.msg}`,{duration: 3000,},)
         stop()
          
            
           }  
     
 
     })
  } catch (error) { 
   stop()

   throw error;}      
   

        }
  
  
  
  
  
  
      const update = async(id,value,value_) => {
      
        
       
  
  
          loading()
  
       
  
          try {
          
          
            var formData = new FormData();
            formData.append("username",username); 
            formData.append("key",key); 
         
      
            formData.append("id",id);
  
            formData.append("value",value);
            
            formData.append("value_",value_);
  
      
  
           
          
            
            axios.post(`https://admin.al-hubaishi.com/Api/update_activity.php`, formData)
            .then(res => {
              
        
  
  
  
      if(res.data.success === true){
       
            stop()
            getData()

          toast.success(`${res.data.msg}`,{duration: 3000,},)
     
      
      
         
  
      }else{
        stop()
          toast.error(`${res.data.msg}`,{duration: 3000,},)
      }
  
  
  
     
          
            })
         } catch (error) { 
      stop()
      toast.error(`${error}`,{duration: 3000,},)
      throw error;}      
        
    
        
  
  
  
  
      
  
  
  
      }
  
  
  
  
  
  
     

      const update_img = async(id) => {
      
      



     if(file !== ''){
        
        loading()
        try {
        
        
            var formData = new FormData();
            formData.append("username",username); 
            formData.append("key",key); 
         
      
            formData.append("photo",file);
            formData.append("id",id);
           
  
      
  
           
            
            axios.post(`https://admin.al-hubaishi.com/Api/update_activity_img.php`, formData)
            .then(res => {
              
        
  
  
  
      if(res.data.success === true){
            
            handleReset()
            stop()
            getData()
  
          toast.success(`${res.data.msg}`,{duration: 3000,},)
     
      
      
         
  
      }else{
        stop()
          toast.error(`${res.data.msg}`,{duration: 3000,},)
      }
  
  
  
     
          
            })
         } catch (error) { 
      stop()
      toast.error(`${error}`,{duration: 3000,},)
      throw error;}      
        
    
        
  
  
  
  
     }else{
        toast.error(`قم باختيار صورة اولا`,{duration: 3000,},)
     }


    



    }
     
  
  
  
  
  
      const [file,setFile] = useState("");
        
      const setimgfile = (e)=>{
        setFile(e.target.files[0])
      }
      
      
const inputFile = useRef(null);

const handleReset = () => {
  if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
  }
};
  
  
   
  
  
  


return (
 <div className="w-full h-screem pt-[80px] ">


<div className="mb-[10px]
">
{activities.length > 0 ? 
activity.map((activity,indx) => 

<>

<h2 className=" font-bold text-[30px] mt-2  "> { `${activities[Number(4+indx)].value}`  } </h2>
    

<div className="flex mt-2" >
  <img className="m-auto rounded" src={`https://al-hubaishi.com/pic/${activities[Number(8+indx)].value }`} alt="" width={'200px'} />
</div>
<div className="form1 w-[80%] max-w-[1000px]  m-auto"   >

<p className="title1"> تعديل الصورة  </p>
 
 <label  className="">
 <input className="input" id="img" name="img" ref={inputFile} onChange={setimgfile} accept="image/*" type="file" placeholder="" required/>
         <span className=""> الصورة </span>
     </label> 


 
     
     <button onClick={()=>update_img(activities[Number(8+indx)].id)} className="submit">تحديث</button>

</div>

    <div className="form1 w-[80%] max-w-[1000px]  m-auto"  >
      <p className="title1"> مقدمة {`${activities[Number(4+indx)].value}` }</p>
    <p  className="message" > عربي </p>
   
   



   <label  className=" ">
        <textarea dir="rtl" required className="input" id="about" maxLength={2000} name="about" rows={7}  value={ activities[Number(0+indx)].value  }   placeholder=""
         onChange={(e)=>{
            
            var new_arr = [...activities]; 
          
          new_arr[Number(0+indx)].value = e.target.value;
          
          setActivities(new_arr);
           
      
             }}
          />
        <span className="mt-[140px] ">المقدمة</span>
    </label> 

 
<hr></hr>
<p  className="message" > English </p>
   <label  className=" ">
        <textarea required className="input" id="about_" maxLength={2000} name="about_" rows={7}  value={ activities[Number(0+indx)].value_en  }   placeholder=""
          onChange={(e)=>{
            
            var new_arr = [...activities]; 
          
          new_arr[Number(0+indx)].value_en = e.target.value;
          
          setActivities(new_arr);
           
    
             }}
          />
        <span className="mt-[140px] ">About</span>
    </label> 

    

   
 
    <button onClick={()=>update(activities[Number(0+indx)].id ,  activities[Number(0+indx)].value  , activities[Number(0+indx)].value_en )}  className="submit">حفظ</button>

   
</div>
<br />
<hr></hr>
</>

):''}

 







   

</div>




  </div>
);
};

export default Activity;
