// Modal.js

import React from "react";
import { MdClose } from "react-icons/md";

const Modal = ({ isOpen, onClose, children }) => {
	if (!isOpen) return null;

	return (
		<div
		
			style={{
				position: "fixed",
				zIndex:1000,
				top: 0,
				left: 0,
               
				width: "100%",
				height: "100%",
				background: "rgba(0, 0, 0, 0.2)",
			backdropFilter:'blur(8px)',
            alignItems: 'center',
            display: "flex",
            justifyContent:'center'
  

			}}
		>
		<button onClick={onClose} style={{zIndex:'1001',position:'absolute'}} className='top-[4px] left-[10px] p-2 bg-[#dbdbdb]  rounded hover:opacity-70 m-1 '>
            <MdClose color="black" size={30} />
            </button>
			<div
				style={{
					background: "white",
					overflow:'auto',
					overflowX:'auto',
                    maxHeight:'80%',
                    maxWidth:'1000px',
					width: '80%',
                  
					margin: "auto",
					padding: "2%",
			
					borderRadius: "10px",
					boxShadow: "2px solid black",
				}}
			>
          
           

				{children}
			</div>
		</div>
	);
};

export default Modal;
