import axios from "axios";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import toast from "react-hot-toast";
import Modal from "../../Modal";
import { MdClose, MdMoreVert } from "react-icons/md";

const Roles = ({loading, stop}) => {

  
//const auth = useAuthUser()

//const auth = useAuthUser()

//const key = auth().key;

//const username = auth().username;

useEffect( () => {
 
    allRoles();
  
  }, []); 



  const auth = useAuthUser()

  const key = auth().key; 
    
  const username = auth().username;


  


    const [products, setProducts] = useState([]);

    const allRoles = async () => {
    
      loading()
    try {
     
    
     
    
      
      axios.get(`https://admin.al-hubaishi.com/Api/Roles.php?key=${key}&username=${username}`)
      .then(res => {
        if(res.data.success === true){

        setProducts(res.data.productlist.productdata);
 
        stop()
           
        }else{
          
          stop()
           
                toast.error(`${res.data.msg}`,{duration: 3000,},)
            }  
      

      })
   } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
    
    
    }
    
    



const labels = [

{id:1,name:'المقدمة'},
{id:2,name:'الصور'},
{id:3,name:'عنا'},
{id:4,name:'النشاطات'},
{id:5,name:'الوكالات'},
{id:6,name:'الفروع'},
]








    
const [data, setData] = useState({

  name:'',



});




const onChangeValue = (e) => {
  setData({
    ...data,
    [e.target.name]:e.target.value
  });

  
} 



const add = async(e) => {

  e.preventDefault();
 
  loading()
  try {
      
  
    var formData = new FormData();
    formData.append("username",username); 
    formData.append("key",key); 
 
    formData.append("name",data.name); 


    
    axios.post(`https://admin.al-hubaishi.com/Api/AddRole.php`, formData)
    .then(res => {
      
 



if(res.data.success === true){

  setData({
      name:'',

    
         
     })

     
     stop()
           
  toast.success(`${res.data.msg}`,{duration: 3000,},)

  setProducts([]);
  allRoles()
 

}else{ 
  stop()
  
  toast.error(`${res.data.msg}`,{duration: 3000,},)
}




  
    })
   } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      

  
  }














const [updatedata, setUpdatedata] = useState({
  id:'',
  name:'',


});


const onChangeValue1 = (e) => {
  setUpdatedata({
    ...updatedata,
    [e.target.name]:e.target.value
  });

  
} 






    const [open, setOpen] = React.useState(false);
 
    const handleClose = () => {
      setIndex(-1)
        setOpen(false);
    };
    
    const handleOpen = () => {
        setOpen(true);
    };
    

 


    const [open1, setOpen1] = React.useState(false);
 
    const handleClose1 = () => {
      setIndex(-1)
        setOpen1(false);
    };
    
    const handleOpen1 = () => {
        setOpen1(true);
    };
    

  const update = async(e) => {
    
    e.preventDefault();
   
    loading()

    try {
        
    
      var formData = new FormData();
      formData.append("username",username); 
      formData.append("key",key); 
      formData.append("id",updatedata.id); 
      formData.append("name", updatedata.name); 

      
      
      axios.post(`https://admin.al-hubaishi.com/Api/Edit_Role.php`, formData)
      .then(res => {
        




if(res.data.success === true){
 
    handleClose1()
   
      
    stop()
           
    toast.success(`${res.data.msg}`,{duration: 3000,},)

    setProducts([]);
    allRoles();
   

}else{    
  
  stop()

    toast.error(`${res.data.msg}`,{duration: 3000,},)
}




    
      })
  } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
    
    

    


  }
    








    
  const Privillages = async(e) => {
    
    e.preventDefault();
   
   
    loading()

    try {

    
     
      
      axios.post(`https://admin.al-hubaishi.com/Api/Privillages.php?key=${key}&username=${username}`, JSON.stringify(privillages))
      .then(res => {
        




if(res.data.success === true){
 
    handleClose()
   
      
  stop()
  
    toast.success(`${res.data.msg}`,{duration: 3000,},)

    setProducts([]);
    allRoles();
   

}else{ 
  stop()
  
    toast.error(`${res.data.msg}`,{duration: 3000,},)
}




    
      })
  } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
    
    

    
  }



    


    const [index, setIndex] = useState(-1);
      
      


    const [privillages, setPrivillages] = useState([]);
    
    

    

return (
  <div className="pt-[60px]">




<h1>  الوظائف والصلاحيات   </h1>







<div className="mb-[150px] mt-[50px] ">



<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={add}>
      <p className="title1"> اضافة وظيفة  </p>
    <p  className="message" dir="rtl"> اضافة وظيفة </p>
       
   
    <label className="">
        <input className="input" id="name" name="name" value={data.name} onChange={onChangeValue} type="text" placeholder="" required/>
        <span>اسم الوظيفة</span>
    </label> 
  
   
    
    <button className="submit">أضافة</button>

   
</form>
<hr></hr>



{products.map((item,i) => 

<>
<div  className= {`flex mt-2  `}>



 <div dir="rtl" style={{placeContent:"center" , verticalAlign:'middle'}}  
 
 className={` m-auto flex max-w-[1000px] w-[80%] mb-2 p-2 rounded bg-gray-300`} > 



 
 
<div style={{placeContent:"center" , verticalAlign:'middle'}} 
className= {`w-[10%]  `} >
    <div className="font-bold text-[15px] "> 
      <p style={{wordBreak:'break-word'}}>{item.id} </p>
    </div>
  </div>




  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[80%] ">
    <div className="font-bold text-[15px] "> 
      <p style={{wordBreak:'break-word'}}>{item.name} { Number(item.id)===1 ? '(SYS)' : ''}</p>
    </div>
  </div>




{ Number(item.id)===1 ? 
  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[10%] ">

  <div className="font-bold text-[15px] "> 
      <p style={{wordBreak:'break-word'}}></p>
    </div>
 </div>

:
  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[10%] ">

  <button onClick={()=>{
     
    
    if(index===i){
        setIndex(-1)
        setUpdatedata({
          id:'',
          name:'',
        })
        setPrivillages([])
    }else{

      setUpdatedata({
          id:item.id,
          name:item.name,
        })
        setPrivillages(item.privillages)
        setIndex(i)
    }
    
  }} >
            {index===i ?  <MdClose color="grey" size={30} />
            :<MdMoreVert color="grey" size={30} />}
            </button>
          { index===i ? <div className="dropdown">
            <div className="dropdown-content">

<button className="p-2 bg-[#dbdbdb]  w-[120px] rounded hover:opacity-70 m-1" onClick={()=>{

handleOpen()
}} >
الصلاحيات
</button>
<br />

<button className="p-2 bg-[#dbdbdb]  w-[120px] rounded hover:opacity-70 m-1" onClick={()=>{

handleOpen1()
}} >
تعديل
</button>
</div>
  
</div>:''}
 
 </div>

}


    </div>
</div>

<hr />
</>

)}




 
<hr></hr>
    </div>


    <Modal isOpen={open1} onClose={handleClose1}>
                <>
           

<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={update}>
      <p className="title1"> تحديث وظيفة  </p>
    <p  className="message" dir="rtl">  تحديث وظيفة رقم <b>{updatedata.id}</b> </p>
       
   
    <label className="">
        <input className="input" id="name" name="name" value={updatedata.name} onChange={onChangeValue1} type="text" placeholder="" required/>
        <span>اسم الوظيفة</span>
    </label> 
  
   
    
    <button className="submit">تحديث</button>

   
</form>
<hr></hr>


                </>
            </Modal>




   
            <Modal isOpen={open} onClose={handleClose}>
                <>
                   
  <div className="form1 w-[100%] max-w-[1000px]  m-auto"  >
      <p className="title1"> الصلاحيات  </p>
    <p  className="message" > الصلاحيات للوظائف  </p>
      
   
<div dir="rtl" className="flex"  style={{Width:'100%',display:'flex'}} >

<div className="m-auto flex" style={{overflowX:'auto' , width:'100%' }}>
  <table>

<tr>
    <th>القسم</th>
    <th>العرض</th>
    <th>الأضافة</th>
    <th>التعديل</th>
    <th>الحذف</th>
</tr>


{
  privillages&&privillages.length > 0 ?


  privillages&&privillages.map((item,i) => 
      <tr>
    <td><b> {labels[i].name}</b></td>


    
    <td><input type="checkbox" id="" className="checkbox" checked={Number(item.read_)}  onClick={()=>{ 
      
      var new_arr = [...privillages]; 
      
      new_arr[i].read_ = Number(item.read_) > 0 ? 0 : 1;
      
      setPrivillages(new_arr);
      
      } } /></td>
    
    <td><input type="checkbox" id="" className="checkbox" checked={Number(item.create_) }  onClick={()=>{ 
      
      var new_arr = [...privillages]; 
      
      new_arr[i].create_ =Number(item.create_) > 0 ? 0 : 1;
      
      setPrivillages(new_arr);
      
      } } /></td>
    
    
    

    <td><input type="checkbox" id="" className="checkbox" checked={Number(item.update_) }  onClick={()=>{ 
      
      const new_arr = [...privillages]; 
      
      new_arr[i].update_ = Number(item.update_) > 0 ? 0 : 1;
      
      setPrivillages(new_arr);
      
      } }  /></td>
    
    <td><input type="checkbox" id="" className="checkbox" checked={Number(item.delete_) }  onClick={()=>{ 
      
      var new_arr = [...privillages]; 
      
      new_arr[i].delete_ = Number(item.delete_) > 0 ? 0 : 1;
      
      setPrivillages(new_arr);
      
      } } /></td>
    
  

</tr>

  )
  :''
}

    
  </table>

  </div>
  </div>

 
    <button onClick={Privillages} className="submit">حفظ</button>

   
</div>
<hr></hr>

                </>
            </Modal>





  </div>
);
};

export default Roles;
