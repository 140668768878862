import React, { forwardRef, useEffect, useRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useAuthUser } from "react-auth-kit";
import { MdAddCircle, MdClose, MdDelete, MdDeleteSweep, MdMoreVert } from "react-icons/md";
import { activity } from "../data";
import {
    VerticalTimeline,
    VerticalTimelineElement,
  } from "react-vertical-timeline-component";
  
  import "react-vertical-timeline-component/style.min.css";
import Modal from "../../Modal";
  


const AboutUS = ({loading, stop}) => {

  
     
    const auth = useAuthUser()

    const key = auth().key; 
      
    const username = auth().username;

  useEffect( () => {
   getData()
  
   },[]);


  
  
   const [data, setData] = useState({
    title:'',
    description:'',
    title_:'',
    description_:'',

  });
  
  
  
  
  const [data_, setData_] = useState({
    title:'',
    title_:'',
 

  });
  
  
        const onChangeValue = (e) => {
          setData({
            ...data,
            [e.target.name]:e.target.value
          });
  
          
        } 
        
  

        const onChangeValue1 = (e) => {
            setData_({
              ...data_,
              [e.target.name]:e.target.value
            });
    
    
          } 
          
        





  
          const [updatedata, setUpdateData] = useState({
           
            title:'',
            description:'',
            title_:'',
            description_:'',
        
          });
          




          const onChangeValue_ = (e) => {
            setUpdateData({
              ...updatedata,
              [e.target.name]:e.target.value
            });
    
    
          } 
          



        const [agents, setAgents] = useState([])
  
        const getData = async () => {
           
   loading()
     
   try {
    
     
    
   
     
     axios.get(`https://admin.al-hubaishi.com/Api/Us.php?key=${key}&username=${username}`)
     .then(res => {
       if(res.data.success === true){
 
     
        setAgents(res.data.datalist.about_us)
    
      stop()
          
       }else{
         

         toast.error(`${res.data.msg}`,{duration: 3000,},)
         stop()
          
            
           }  
     
 
     })
  } catch (error) { 
   stop()

   throw error;}      
   

        }
  
  
  
        const Add = async(e) => {
      
            e.preventDefault();
         
       
            if(added){
              toast.loading(`يرحى الأنتظار حتى يتم معالجة الطلب السابق`,{duration: 3000,},)
            }else{
    
            loading()
    
         
     
            try {
            
            
              var formData = new FormData();
              formData.append("username",username); 
              formData.append("key",key); 
           
              formData.append("title",data.title);
              formData.append("title_",data.title_);
              formData.append("description",data.description);
              formData.append("description_",data.description_);
           
            
              
              axios.post(`https://admin.al-hubaishi.com/Api/add_aboutus.php`, formData)
              .then(res => {
                
          
    
    
    
        if(res.data.success === true){
          setData({
          title:'',
          description:'',
          title_:'',
          description_:'',

         })
         setAdded(false)
              stop()
              getData()
  
            toast.success(`${res.data.msg}`,{duration: 3000,},)
       
        
        
           
    
        }else{
          setAdded(false)
          stop()
            toast.error(`${res.data.msg}`,{duration: 3000,},)
        }
    
    
    
       
            
              })
           } catch (error) { 
            setAdded(false)
        stop()
        toast.error(`${error}`,{duration: 3000,},)
        throw error;}      
          
      
          
    
    
           }
    
        
    
    
    
        }
    
     
     
  
  
      const update = async(id,i) => {
      
          //e.preventDefault();
       
  
          loading()
    
         
     
          try {
          
          
            var formData = new FormData();
            formData.append("username",username); 
            formData.append("key",key); 
         
            formData.append("id",id);
            formData.append("title",agents[i].title);
            formData.append("title_",agents[i].title_en);
            formData.append("description",agents[i].description);
            formData.append("description_",agents[i].description_en);
         
          
            
            axios.post(`https://admin.al-hubaishi.com/Api/Edit_aboutus.php`, formData)
            .then(res => {
              
        
  
  
      if(res.data.success === true){
       
            stop()
            getData()

          toast.success(`${res.data.msg}`,{duration: 3000,},)
     
      
      
         
  
      }else{
        stop()
          toast.error(`${res.data.msg}`,{duration: 3000,},)
      }
  
  
  
     
          
            })
         } catch (error) { 
      stop()
      toast.error(`${error}`,{duration: 3000,},)
      throw error;}      
        
    
        
  
  
  
  
      
  
  
  
      }
  

      
    const del = async(id) => {
      
      //e.preventDefault();
   

      loading()

     
 
      try {
      
      
        var formData = new FormData();
        formData.append("username",username); 
        formData.append("key",key); 
     
        formData.append("id",id);
       
     
      
        
        axios.post(`https://admin.al-hubaishi.com/Api/delete_aboutus.php`, formData)
        .then(res => {
          
    


  if(res.data.success === true){
   
        stop()
        getData()

      toast.success(`${res.data.msg}`,{duration: 3000,},)
 
  
  
     

  }else{
    stop()
      toast.error(`${res.data.msg}`,{duration: 3000,},)
  }



 
      
        })
     } catch (error) { 
  stop()
  toast.error(`${error}`,{duration: 3000,},)
  throw error;}      
    

    




  



  }




      const [added, setAdded] = useState(false);  
  

      const Add_ = async(id) => {
      
        //e.preventDefault();
     
if(added){
  toast.loading(`يرحى الأنتظار حتى يتم معالجة الطلب السابق`,{duration: 3000,},)
}
else if (data_.title === '' || data_.title_ === ''){
  toast.error(`يرحى ادخال البيانات`,{duration: 3000,},)
}else{



  setAdded(true)
  loading()

 

  try {
  
  
    var formData = new FormData();
    formData.append("username",username); 
    formData.append("key",key); 
 
    formData.append("id",id);
    formData.append("value",data_.title);
    formData.append("value_",data_.title_);
   
 
  
    
    axios.post(`https://admin.al-hubaishi.com/Api/Add_points.php`, formData)
    .then(res => {
      



if(res.data.success === true){
setData_({
  title:'',
title_:''
})
    stop()
    getData()

  toast.success(`${res.data.msg}`,{duration: 3000,},)

  setAdded(false)

 

}else{
stop()
  toast.error(`${res.data.msg}`,{duration: 3000,},)
}




  
    })
 } catch (error) { 
stop()
toast.error(`${error}`,{duration: 3000,},)
throw error;}      



}
  

    



    }





  
    const del_ = async(id) => {
      
      //e.preventDefault();
   

      loading()

     
 
      try {
      
      
        var formData = new FormData();
        formData.append("username",username); 
        formData.append("key",key); 
     
        formData.append("id",id);
       
     
      
        
        axios.post(`https://admin.al-hubaishi.com/Api/delete_point.php`, formData)
        .then(res => {
          
    


  if(res.data.success === true){
   
        stop()
        getData()

      toast.success(`${res.data.msg}`,{duration: 3000,},)
 
  
  
     

  }else{
    stop()
      toast.error(`${res.data.msg}`,{duration: 3000,},)
  }



 
      
        })
     } catch (error) { 
  stop()
  toast.error(`${error}`,{duration: 3000,},)
  throw error;}      
    

    




  



  }








  

  
  
const [add, setAdd] = useState(-1);
          
  
  

  
  



function isEven(n) {
    return (n % 2 == 0);
  }
  
  
  

return (
 <div className="w-full h-screem pt-[80px] ">


<div className="mb-[10px]
">





<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={Add}>
 
 



 

 <p className="title1"> عنا  </p>
 
 

     <p  className="message" > عربي </p>
     <label  className=" ">
         <input dir="rtl" maxLength={50} required className="input" id="title" name="title"  value={data.title} onChange={onChangeValue} placeholder=""  />
         <span className=""> عنوان </span>
     </label> 
    <label  className=" ">
         <textarea dir="rtl" required className="input" id="description" maxLength={2000} name="description" rows={7}  value={data.description} onChange={onChangeValue} placeholder=""  />
         <span className="mt-[140px] ">الوصف</span>
     </label> 
 
     
     <hr></hr>
     <p  className="message" > English </p>
     <label  className=" ">
         <input maxLength={50} required className="input" id="title_" name="title_"  value={data.title_} onChange={onChangeValue} placeholder=""  />
         <span className=""> Title </span>
     </label> 
  
    <label  className=" ">
         <textarea required className="input" id="description_" maxLength={2000} name="description_" rows={7}  value={data.description_} onChange={onChangeValue} placeholder=""  />
         <span className="mt-[140px] ">Description</span>
     </label> 
     <hr></hr>
    
     
     <button className="submit">أضافة</button>
 
    
 </form>
 






   

</div>



  
<hr></hr>
<div className="m-4">
  {agents.map((Aboutus,index) => 

    <VerticalTimelineElement
      contentStyle={{
        background: `${isEven(index) ? '#E6DEDD':'#dbdbdb'}`,
        color: "#fff",
        
      }}
      contentArrowStyle={{ borderRight: "7px solid  #232631" }}
      date={Aboutus.date}
      iconStyle={{ background: `${isEven(index) ? '#E6DEDD':'#3b3b3b'}` }}
      icon={
        <div dir="" className='flex justify-center items-center w-full h-full'>
          <img
            src='https://al-hubaishi.com/hubaishi-logo.png'
            alt='مؤسسة محمد عبدالله الحبيشي'
            className='w-[60%] h-[60%] object-contain'
          />
        </div>
      }
    >


<button onClick={()=>{
          
          

          confirmAlert({
                  title: 'Confirm to Submit',
                  message:` هل أنت متأكد ، تريد حذف هذا الجزء كاملا ؟  `,
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: () => {
                        del(Aboutus.id)
          
          }
                    },
                    {
                      label: 'No',
                      onClick: () => { }
                    }
                  ]
                });
          
                    
                   }} ><MdDeleteSweep className="" color="red" size={25}/>

                    
                   </button> 


    <div style={{background:'transparent'}}  className="form1 text-black">
      <div  className=" " >
      <div dir="rtl" className="flex">
      
       
     <label  className="w-full m-1">
         <input required dir="rtl" placeholder=""  type=""  value={agents[index]?.title}  onChange={(e)=>{
            
        var new_arr = [...agents]; 
      
      new_arr[index].title = e.target.value;
      
      setAgents(new_arr);
       
  //    toast.success(payments[i]?.note)
         }} className="input"/>
         <span> عنوان </span>
     </label>

     <label  className="w-full m-1 ">
         <input  required placeholder=""  type=""  value={agents[index]?.title_en}  onChange={(e)=>{
            
        var new_arr = [...agents]; 
      
      new_arr[index].title_en = e.target.value;
      
      setAgents(new_arr);
       
  //    toast.success(payments[i]?.note)
         }} className="input"/>
         <span>Title</span>
     </label>
          </div>
        
      
      </div>
      <hr />
      <br></br>
      <label  className="w-full m-1">
         <textarea required rows={5}  dir="rtl" placeholder=""  type=""  value={agents[index]?.description}  onChange={(e)=>{
            
        var new_arr = [...agents]; 
      
      new_arr[index].description = e.target.value;
      
      setAgents(new_arr);
       
  //    toast.success(payments[i]?.note)
         }} className="input"/>
         <span  className="mt-[90px] "> الوصف </span>
     </label>


     <label  className="w-full m-1">
         <textarea required rows={5}   placeholder=""  type=""  value={agents[index]?.description_en}  onChange={(e)=>{
            
        var new_arr = [...agents]; 
      
      new_arr[index].description_en = e.target.value;
      
      setAgents(new_arr);
       
  //    toast.success(payments[i]?.note)
         }} className="input"/>
         <span  className="mt-[90px] "> Description </span>
     </label>
    
     <button 
     
     onClick={()=>update(Aboutus.id,index)} className="submit">تحديث</button>
           </div>


      <ul   className='mt-5 list-disc ml-5 mr-5 space-y-2'>
        {Aboutus.points.map((point, index) => (
          <li
            key={`About-point-${index}`}
            className=' text-black font-bold text-[14px] pl-1 tracking-wider'
          >
          <div style={{placeContent:"center" , verticalAlign:'middle'}} className="flex">
          <p className="w-full">{ point.value_en}</p> &ensp; <p className="w-full" dir="rtl">{point.value  }</p>
         <button onClick={()=>{
          
          

confirmAlert({
        title: 'Confirm to Submit',
        message:` هل أنت متأكد ، تريد حذف النقطة ؟  `,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              del_(point.id)

}
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });

          
         }} ><MdDelete className="m-auto" color="red" size={25}/></button> 
          </div>
          

          </li>
          
        ))}
      </ul>
      
<br />
<hr></hr>


{add === index ?
<div style={{background:'transparent'}} className="form1 text-black w-[80%] max-w-[1000px]  m-auto "  >
    
<MdClose className="cursor-pointer" onClick={()=>{
  setData_({
  title:'',
  title_:'',
})
  setAdd(-1)}} size={30} />
 
 
 <p className="title1"> اضافة نقاط
   </p>
 
 

     <p  className="message" > عربي </p>
     <label  className=" ">
         <input dir="rtl" maxLength={50} required className="input" id="title" name="title"  value={data_.title} onChange={onChangeValue1} placeholder=""  />
         <span className=""> عنوان </span>
     </label> 
 
     
   
     <p  className="message" > English </p>
     <label  className=" ">
         <input maxLength={50} required className="input" id="title_" name="title_"  value={data_.title_} onChange={onChangeValue1} placeholder=""  />
         <span className=""> Title </span>
     </label> 
  
     <div className=" p-2  " >
           
           <MdAddCircle className='m-auto cursor-pointer'  
           onClick={()=>Add_(Aboutus.id)}
           color="steelblue" size={25}/>
           
           </div> 
   
    
 
    
 </div>
 

  
 :


<button className="mb-10 text-black  mt-5" onClick={()=>{
  
  setAdd(index)
setData_({
  title:'',
  title_:'',
})
}} >اضافة نقطة ؟</button>

}


    </VerticalTimelineElement>

)}



</div>




<br />














    </div>






);
};

export default AboutUS;
