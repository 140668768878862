import axios from "axios";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useAuthUser, useSignOut } from "react-auth-kit";
import toast from "react-hot-toast";
import Modal from "../../Modal";
import { MdClose, MdMoreVert } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Profile = ({loading, stop}) => {

  

useEffect( () => {
 
    userinfo()
  }, []); 



  const auth = useAuthUser()

  const key = auth().key; 
    
  const username = auth().username;


  
    
const [data, setData] = useState({


  
    email:'',
  
  });
  

  const onChangeValue = (e) => {
    setData({
      ...data,
      [e.target.name]:e.target.value
    });
  
    
  } 



  const [email, setEmail] = useState('')


    const [user, setUser] = useState([]);

    const userinfo = async () => {
    
      loading()
    try {
     
    
     
    
      
      axios.get(`https://admin.al-hubaishi.com/Api/user.php?key=${key}&username=${username}`)
      .then(res => {
        if(res.data.success === true){

      setUser(res.data.productlist.productdata[0]);
 setData({
    email:res.data.productlist.productdata[0].email
    
 })

 setEmail(res.data.productlist.productdata[0].email)
        stop()
           
        }else{
          
          stop()
           
                toast.error(`${res.data.msg}`,{duration: 3000,},)
            }  
      

      })
   } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
    
    
    }
    
    


    const [userInfo, setuserInfo] = useState({
        confirm_password: '',
       
        password: '',
      });
      
         

      const onChangeValue1 = (e) => {
        setuserInfo({
          ...userInfo,
          [e.target.name]:e.target.value
        });
      } 
    

      const logout = useSignOut();


      const navigate=useNavigate();

      const signout = () =>{

        toast.loading('LogOut...' ,{duration:1000})
        
            setTimeout(() => {
             logout()
        
          }, 1000);
         
        
        
          };



      const change_password = async(event) => {

        event.preventDefault();
        if(userInfo.password.length > 8 ){
          
            loading()
       
          try {
       
          
       
           
           var formData = new FormData();
           formData.append("username",username); 
           formData.append("key",key); 
       
           formData.append("Pass",userInfo.confirm_password); 
           formData.append("newPass",userInfo.password); 
        
            
       
           
            axios.post(`https://admin.al-hubaishi.com/Api/change_pass.php`,formData )
            .then(res => {
             
       
             if(res.data.success===true){
               toast.success(res.data.msg,{duration: 3000,},);
              navigate('/')
       
               setTimeout(() => {
               
                stop()
                signout()
             }, 1000);
            
             
             }else{
                stop()
       
              toast.error(res.data.msg,{duration: 3000,},);
             }
              
              
             })
       
            } catch (error) { 
                stop()
           toast.error(`${error}`,{duration: 3000,},)
           throw error;}   
       
       
       
         }else{
       
            stop()
       toast.error('كلمة السر يجب ان تكون اكبر من 8 احرف او ارقام',{duration: 3000,},);
       
         }
        
        };



        const changeEmail = async(event) => {

          event.preventDefault();
       
            
              loading()
         
            try {
         
            
         
             
             var formData = new FormData();
             formData.append("username",username); 
             formData.append("key",key); 
         
             formData.append("email",data.email); 
             
              
         
             
              axios.post(`https://admin.al-hubaishi.com/Api/changeEmail.php`,formData )
              .then(res => {
               
         
               if(res.data.success===true){
                 toast.success(res.data.msg,{duration: 3000,},);
              
                 userinfo()


               }else{
                  stop()
         
                toast.error(res.data.msg,{duration: 3000,},);
               }
                
                
               })
         
              } catch (error) { 
                  stop()
             toast.error(`${error}`,{duration: 3000,},)
             throw error;}   
         
         
         
          
          
          };










        const sendCode = async() => {

            
              loading()
         
            try {
         
            
         
             
             var formData = new FormData();
             formData.append("username",username); 
             formData.append("key",key); 
         
         
             
              axios.post(`https://admin.al-hubaishi.com/Api/verficationCode.php`,formData )
              .then(res => {
               
         
               if(res.data.success===true){
                 toast.success(res.data.msg,{duration: 3000,},);
              
               //  userinfo()
handleOpen()

               }else{
                  stop()
         
                toast.error(res.data.msg,{duration: 3000,},);
               }
                
                
               })
         
              } catch (error) { 
                  stop()
             toast.error(`${error}`,{duration: 3000,},)
             throw error;}   
         
         
         
          
          
          };



          const verfiy = async(e) => {

            e.preventDefault();
            loading()
       
          try {
       
          
       
           
           var formData = new FormData();
           formData.append("username",username); 
           formData.append("key",key); 
           formData.append("code",code);
       
           
            axios.post(`https://admin.al-hubaishi.com/Api/checkver_active.php`,formData )
            .then(res => {
             
       
             if(res.data.success===true){
               toast.success(res.data.msg,{duration: 3000,},);
          userinfo()
handleClose()

             }else{
                stop()
       
              toast.error(res.data.msg,{duration: 3000,},);
             }
              
              
             })
       
            } catch (error) { 
                stop()
           toast.error(`${error}`,{duration: 3000,},)
           throw error;}   
       
       
       
        
        
        };







//forget password
        
        const sendCode_ = async() => {

            
          loading()
     
        try {
     
        
     
         
         var formData = new FormData();
         formData.append("username",username); 
         formData.append("key",key); 
     
     
         
          axios.post(`https://admin.al-hubaishi.com/Api/verficationCode.php`,formData )
          .then(res => {
           
     
           if(res.data.success===true){
             toast.success(res.data.msg,{duration: 3000,},);
          
        //     userinfo()
handleOpen1()

           }else{
              stop()
     
            toast.error(res.data.msg,{duration: 3000,},);
           }
            
            
           })
     
          } catch (error) { 
              stop()
         toast.error(`${error}`,{duration: 3000,},)
         throw error;}   
     
     
     
      
      
      };

        const verfiy_ = async(e) => {

          e.preventDefault();
          loading()
     
        try {
     
        
     
         
         var formData = new FormData();
         formData.append("username",username); 
         formData.append("key",key); 
         formData.append("code",code);
     
         
          axios.post(`https://admin.al-hubaishi.com/Api/checkver_active.php`,formData )
          .then(res => {
           
     
           if(res.data.success===true){
             toast.success(res.data.msg,{duration: 3000,},);
          
           //  userinfo()
             resetPassword()

           }else{
              stop()
     
            toast.error(res.data.msg,{duration: 3000,},);
           }
            
            
           })
     
          } catch (error) { 
              stop()
         toast.error(`${error}`,{duration: 3000,},)
         throw error;}   
     
     
     
      
      
      };



        const resetPassword = async() => {

          handleClose1()
          loading()
     
        try {
     
        
     
         
         var formData = new FormData();
         formData.append("username",username); 
         formData.append("key",key); 
       
     
         
          axios.post(`https://admin.al-hubaishi.com/Api/ResetPassword.php`,formData )
          .then(res => {
           
     
           if(res.data.success===true){
             toast.success(res.data.msg,{duration: 8000,},);
          
           
          
            navigate('/')
      
              setTimeout(() => {
              
               stop()
               signout()
            }, 1000);
           

           }else{
              stop()
     
            toast.error(res.data.msg,{duration: 3000,},);
           }
            
            
           })
     
          } catch (error) { 
              stop()
         toast.error(`${error}`,{duration: 3000,},)
         throw error;}   
     
     
     
      
      
      };


          const [open, setOpen] = useState(false);
 
          const handleClose = () => {
              setOpen(false);
              setCode('')
          };
          
          const handleOpen = () => {
              setOpen(true);
          };
          
      
      

          const [code, setCode] = useState('');
 
  //        const [count, setCount] = useState('');
 

  const [open1, setOpen1] = useState(false);
 
  const handleClose1 = () => {
      setOpen1(false);
      setCode('')
  };
  
  const handleOpen1 = () => {
      setOpen1(true);
  };
  

return (
  <div className="pt-[60px]">

{
 email !== null && email !== '' ? 
  
  Number(user.verfication) > 0 ?
<p  className="message mt-5 " dir="rtl"> الحساب مفعل  </p> : 
<p  className="message mt-5" dir="rtl"> <>يرجى تفعيل بريدك الألكتروني لحسابك  <button onClick={()=>sendCode()} className="text-green-500"> تفعيل </button></> </p>
:
<p  className="message mt-5 " dir="rtl">  قم بادخال وحفظ بريدك الالكتروني   </p> 

}
<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={changeEmail}>
  
    <p className="title1"> {auth().username } </p>
    
       
   
    <label className="">
        <input maxLength={50}  className="input" id="email" name="email" value={data.email} onChange={onChangeValue} type="email" placeholder="" required/>
        <span>  البريد الالكتروني </span>
    </label> 
  
   
    <button className="submit">{ email !== null && email !== '' ? 'تحديث' :'حفظ' }</button>

   
</form>

<br />
<hr />
<br />

      
<form className="form1 w-[80%] max-w-[1000px]  m-auto" onSubmit={change_password}>
      <p className="title1">تغيير كلمة السر </p>
    <p className="message"> أكتب كلمة السر الجديدة وكلمة السر القديمة للتاكيد  </p>
       
            
    
   
    <label>
        <input className="input" id="confirm_password" name="confirm_password" onChange={onChangeValue1} type="password" placeholder="" required/>
        <span> اكتب كلمة السر السابقة  </span>
    </label>
    <label>
        <input className="input" id="password" name="password" onChange={onChangeValue1} type="password" placeholder="" required/>
        <span>كلمة السر الجديدة</span>
    </label>
    <button className="submit">حفظ</button>


     <p className="signin cursor-pointer"> <a onClick={()=>{
      
      if(  Number(user.verfication) > 0){
        sendCode_()
      }else{
        toast.error('قم بتفعيل بريدك الالكتروني اولا',{duration: 3000,},);
      }
    
      
      
      }}> لقد نسيت كلمة السر ؟ </a> </p>
</form>









<Modal isOpen={open} onClose={handleClose}>
<>





<form className="form1 w-[80%] max-w-[1000px]  m-auto"  onSubmit={verfiy} >
 
 



 

 <p className="title1">   رمز التحقق  </p>
 <p className="message">  قم بادخال رمز التحقق  </p>
       

 <label className="">
        <input maxLength={9}  className="input" id="code" name="code" value={code} onChange={(e)=>setCode(e.target.value)} type="text" placeholder="" required/>
        <span>  رمز التحقق    </span>
  
  
     </label> 
 
     <button className="submit">أضافة</button>

   
</form>
 

</>
</Modal>



<Modal isOpen={open1} onClose={handleClose1}>
<>





<form className="form1 w-[80%] max-w-[1000px]  m-auto"  onSubmit={verfiy_} >
 
 



 

 <p className="title1">   رمز التحقق  </p>
 <p className="message"> سيتم تغيير كلمة السر الى (123456789) </p>
       

 <label className="">
        <input maxLength={9}  className="input" id="code" name="code" value={code} onChange={(e)=>setCode(e.target.value)} type="text" placeholder="" required/>
        <span>  رمز التحقق    </span>
  
  
     </label> 
 
     <button className="submit">تهيئة</button>

   
</form>
 

</>
</Modal>







  </div>
);
};

export default Profile;
