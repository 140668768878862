import React from 'react';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Provider } from 'react-redux';
import store from './store';
import { AuthProvider } from 'react-auth-kit';
import { BrowserRouter } from 'react-router-dom';
import PWAPrompt from 'react-ios-pwa-prompt'

ReactDOM.render(

  <AuthProvider
  authType={"cookie"}
  authName={"_auth"}
  cookieDomain={window.location.hostname}
  cookieSecure={false}
>
  <Provider store={store}>
<BrowserRouter >
 <App /><PWAPrompt 
 promptOnVisit={1} timesToShow={5}
 copyTitle="Alhubaishi Dashboard" 
 copySubtitle='يمكنك تحميل نظام التحكم الخاص بك عبر اتباع هذه الخطوات' 
 copyDescription='يحتوي هذا الموقع على وظائف التطبيق. أضفه إلى شاشتك الرئيسية لاستخدامه في وضع الشاشة كاملة وأثناء عدم الاتصال بالإنترنت' 
 copyShareStep='اضغط على زر "مشاركة" في شريط القائمة أدناه'
 copyAddToHomeScreenStep='اضغط على "إضافة إلى الشاشة الرئيسية"'
 appIconPath='logo192.png' />
</BrowserRouter>
</Provider> 
</AuthProvider>  ,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
