import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { MdOpenInNew, MdOutlineSettings } from "react-icons/md";
import Modal from "./Modal";
import Loader from "./Loader";
import toast from "react-hot-toast";
import axios from "axios";

const Header = ({Loading, stop}) => {


  const auth = useAuthUser()

  const key = auth().key; 
    
  const username = auth().username;

  const dispatch = useDispatch();
  
  
  const products =useSelector((state) => state.cart.data);
 
  const check =useSelector((state) => state.cart.check);

  const loading = useSelector((state) => state.cart.status);
 

const logout = useSignOut();
  
const navigate=useNavigate();



    const [showMenu, setShowMenu] = useState(false);

    const handleClose = () => {setShowMenu(false)
      
    }
   


   
  const [width, setWidth] = useState(window.innerWidth);
    
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const signout = () =>{

toast.loading('LogOut...' ,{duration:1000})

    setTimeout(() => {
     logout()

  }, 1000);
 


  };



  const [open, setOpen] = useState(false);
 
  const handleClose1 = () => {
      setOpen(false);
      setShowMenu(false)
  };
  
  const handleOpen = () => {
      setOpen(true);
  };
  

  
 const [userInfo, setuserInfo] = useState({
  confirm_password: '',
 
  password: '',
});

   
 
 const onChangeValue = (e) => {
   setuserInfo({
     ...userInfo,
     [e.target.name]:e.target.value
   });
 } 


  
 
 const change_password = async(event) => {
 Loading()
 event.preventDefault();

 if(userInfo.password.length > 8 ){


   try {

   

    
    var formData = new FormData();
    formData.append("username",username); 
    formData.append("key",key); 

    formData.append("Pass",userInfo.confirm_password); 
    formData.append("newPass",userInfo.password); 
 
     

    
     axios.post(`https://admin.al-hubaishi.com/Api/change_pass.php`,formData )
     .then(res => {
      

      if(res.data.success===true){
        toast.success(res.data.msg,{duration: 3000,},);
        navigate('/')

        setTimeout(() => {
    
     logout()
          stop()
      }, 2000);
     
      
      }else{
        stop()

       toast.error(res.data.msg,{duration: 3000,},);
      }
       
       
      })

     } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}   



  }else{

stop()
toast.error('كلمة السر يجب ان تكون اكبر من 8 احرف او ارقام',{duration: 3000,},);

  }
 
 };



return (
    <>
   
   {
loading ?<Loader /> : ''
}

  <header dir="" style={{zIndex:10}} className=" mb-[60px] fixed w-full flex flex-row items-center justify-between sm:justify-around p-2 border-b-2 bg-gray-100">
      <Link
      onClick={handleClose} 
        to="/"
       className=" font-bold items-center py-2 px-4 bg-gradient-to-b from-[#c73e3e]/90  rounded-tr-full rounded-bl-full w-[200px]  uppercase hover:opacity-70"
      >
    Al-hubaishi
        </Link>
{width < 900 ?

  <nav className="flex flex-col items-end gap-4 font-semibold  ">
        <button
          onClick={() => setShowMenu(!showMenu)}
          className=" font-bold text-xl hover:text-gray-500"
        >
          {showMenu ? <GrClose /> : <GiHamburgerMenu />}
        </button>
        {showMenu && (
          <> 
          <Link onClick={handleClose}  className="mt-[20px] hover:text-gray-500" to={''} >الرئيسية</Link> 
      {products&&products.length > 0 ? 
      
      <>
      {Number(products&&products[0].read ) > 0 ?
      
      <Link onClick={handleClose}  className=" hover:text-gray-500" to={'About'} >المقدمة</Link> 
     
     :'' }
     {Number(products&&products[1].read ) > 0 ?
   <Link onClick={handleClose}  className="hover:text-gray-500" to={'Images'} >الصور</Link>
     :'' }
     {Number(products&&products[2].read ) > 0 ?
      <Link onClick={handleClose}  className="hover:text-gray-500" to={'About_us'} >عنا</Link> 
      
     
     :'' }
     {Number(products&&products[3].read ) > 0 ?
      
     
     <Link onClick={handleClose}  className="hover:text-gray-500" to={'Activities'} >النشاطات</Link>
     :'' }
     
     {Number(products&&products[4].read ) > 0 ?
      <Link onClick={handleClose}  className="hover:text-gray-500" to={'Agencies'} >الوكالات</Link>
      
     
     :'' } 

     {Number(products&&products[5].read ) > 0 ?
      <Link onClick={handleClose}  className="hover:text-gray-500" to={'Branches'} >الفروع</Link>
      
     
     :'' } 
     </>
     :''}

   
      <Link to={'/'} onClick={()=>{signout()}}  
      className="hover:opacity-80 text-red-500 font-bold" >تسجيل خروج</Link> 
       <br />  
      <Link onClick={handleOpen}  className="hover:text-gray-500 "  ><MdOutlineSettings   size={25}/></Link>
      </>
        )}
      </nav>

:



<nav dir="rtl" className=" flex justify-between items-center gap-3 font-semibold">
{products&&products.length > 0 ? 
      
      <>
      <Link onClick={handleClose}  className=" hover:text-gray-500" to={''} >الرئيسية</Link> 
     
      {Number(products&&products[0].read ) > 0 ?
      
      <Link onClick={handleClose}  className="hover:text-gray-500" to={'About'} >المقدمة</Link> 
     
     :'' }
     {Number(products&&products[1].read ) > 0 ?
   <Link onClick={handleClose}  className="hover:text-gray-500" to={'Images'} >الصور</Link>
     :'' }
     {Number(products&&products[2].read ) > 0 ?
      <Link onClick={handleClose}  className="hover:text-gray-500" to={'About_us'} >عنا</Link> 
      
     
     :'' }
     {Number(products&&products[3].read ) > 0 ?
      
     
     <Link onClick={handleClose}  className="hover:text-gray-500" to={'Activities'} >النشاطات</Link>
     :'' }
    
     
     {Number(products&&products[4].read ) > 0 ?
      <Link onClick={handleClose}  className="hover:text-gray-500" to={'Agencies'} >الوكالات</Link>
      
     
     :'' } 

     {Number(products&&products[5].read ) > 0 ?
      <Link onClick={handleClose}  className="hover:text-gray-500" to={'Branches'} >الفروع</Link>
      
     
     :'' } 

     </>
     :''}

      <Link to={'/'} onClick={()=>{signout()}}  
      className="hover:opacity-80 text-red-500 font-bold" >تسجيل خروج</Link> 
      
      <Link onClick={handleOpen}  className="hover:text-gray-500 "  ><MdOutlineSettings   size={25}/></Link>
      </nav>
}
    
    </header>

    <Modal isOpen={open} onClose={handleClose1}>
             <>
    


             <div  className= {`flex   `}>



<div dir="rtl" style={{ verticalAlign:'middle'}}  

className={` m-auto flex max-w-[1000px] w-[90%]   rounded `} > 



<div style={{ verticalAlign:'middle',textAlign:'right'}} 
className= {`m-auto w-[50%] font-bold `} >

     <p style={{wordBreak:'break-word'}}> المستخدم </p>
  
 </div>




 <div style={{ verticalAlign:'middle',textAlign:'left'}} dir="ltr" className=" m-auto w-[50%] ">
 <Link  onClick={()=>handleClose1()} to={'Profile'} className=" hover:opacity-80 w-full">
 <h1>{username}</h1>

<MdOpenInNew size={25}/>
 </Link>
 </div>





   </div>
</div>

<br />
<hr />
<br />{
  Number(check) > 0 ? 
  
  
  
 

 
  <>
<div  className= {`flex mt-2  `}>



<div dir="rtl" style={{ verticalAlign:'middle'}}  

className={` m-auto flex max-w-[1000px] w-[90%] mb-2 p-2 rounded `} > 



<div style={{ verticalAlign:'middle',textAlign:'right'}} 
className= {`w-[50%] font-bold  m-auto `} >

     <p style={{wordBreak:'break-word'}}> الحسابات</p>
  
 </div>




 <div style={{ verticalAlign:'middle',textAlign:'left'}} dir="ltr" className=" w-[50%]  m-auto">

     <p style={{wordBreak:'break-word'}}>
<Link  onClick={()=>handleClose1()} to={'Accounts'} className=" hover:opacity-80 w-full">
<MdOpenInNew size={25}/>
 </Link>

</p>
 
 </div>





   </div>
</div>

<br />
<hr />
<br />

<div  className= {`flex mt-2  `}>



<div dir="rtl" style={{ verticalAlign:'middle'}}  

className={` m-auto flex max-w-[1000px] w-[90%] mb-2 p-2 rounded `} > 



<div style={{ verticalAlign:'middle',textAlign:'right'}} 
className= {`w-[50%] font-bold  m-auto `} >

     <p style={{wordBreak:'break-word'}}> الوظائف والصلاحيات</p>
  
 </div>




 <div style={{ verticalAlign:'middle',textAlign:'left'}} dir="ltr" className=" w-[50%]  m-auto">

     <p style={{wordBreak:'break-word'}}>
<Link  onClick={()=>handleClose1()} to={'Roles'} className=" hover:opacity-80 w-full">
<MdOpenInNew size={25}/>
 </Link>

</p>
 
 </div>





   </div>
</div>
<br />
<hr />
<br />
</>

 :''
}

                </>
            </Modal>



 <Outlet />

</>
);
};

export default Header;
