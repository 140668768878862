import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './Pages/Login';
import Home from './Pages/Home';
import { Toaster } from "react-hot-toast";
import Header from './Header';
import Roles from './Pages/Components/Roles';
import { RequireAuth, useAuthUser } from 'react-auth-kit';
import { useDispatch, useSelector } from "react-redux";
import {useIsAuthenticated} from 'react-auth-kit';
import axios from "axios";
import { addcheck, addData, setStatus } from './store/reducers/CartReducer';
import About from './Pages/Components/About';
import Agencies from './Pages/Components/Agencies';
import Images from './Pages/Components/Images';
import AboutUS from './Pages/Components/AboutUs';
import Activity from './Pages/Components/Activity';
import Branches from './Pages/Components/Branches';
import Accounts from './Pages/Components/Accounts';
import Profile from './Pages/Components/Profile';
 


const App = () => {


  const auth = useAuthUser()

      
  const navigate=useNavigate();
  
  const dispatch = useDispatch();
  
  const isAuthenticated = useIsAuthenticated()


  const Loading = () =>{
    
    dispatch(setStatus({status:true,}));

    };
   
  const Stop = () =>{
    
    dispatch(setStatus({status:false,}));

    };


  const [loading, setLoading] = useState(false);





    const products =useSelector((state) => state.cart.data);

    const check =useSelector((state) => state.cart.check);

  useEffect( () => {
  
    if(isAuthenticated()){
 
      setLoading(true)
      try {
       
      
       
      
        
        axios.get(`https://admin.al-hubaishi.com/Api/Role.php?key=${auth().key}&username=${auth().username}`)
        .then(res => {
          if(res.data.success === true){
    
         dispatch(addData(res.data.productlist.productdata))
         dispatch(addcheck({check :res.data.check}))
       
          setLoading(false)
             
          }else{
            
            setLoading(false)
             
               
              }  
        
    
        })
     } catch (error) { 
      setLoading(false)
   
      throw error;}      
      
    }
    else {
      navigate(`/login`);
    }
   },[]);



const privillages = async(username,key)  =>{


 
    setLoading(true)
    try {
     
    
     
    
      
      axios.get(`https://admin.al-hubaishi.com/Api/Role.php?key=${key}&username=${username}`)
      .then(res => {
        if(res.data.success === true){
  
       dispatch(addData(res.data.productlist.productdata))
       dispatch(addcheck({check :res.data.check}))
     
        setLoading(false)
           
        }else{
          
          setLoading(false)
           
             
            }  
      
  
      })
   } catch (error) { 
    setLoading(false)
 
    throw error;}      
    
 

}


return (

     
             <div  className="App">
             <Toaster 
              toastOptions={{
       
        // Default options for specific types
       /* success: {
          duration: 2000,
          theme: {
            primary: 'green',
            secondary: 'black',
          },
        },*/
      }} /> 
       


  {loading ?
 
<div style={{
				position: "fixed",
				zIndex:1000,
				top: 0,
				left: 0,
               
				width: "100%",
				height: "100%",
				background: "rgba(0, 0, 0, 0.2)",
			backdropFilter:'blur(8px)',
            alignItems: 'center',
            display: "flex",
            justifyContent:'center'
  

			}}> 
<div className="boxes">
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
  
  </div>	
  :
  
  
  
  
   
             <Routes>
   

  <Route path="/login" element={<Login privillage={privillages}    loading={Loading} stop={Stop} />}/>
        
       
    <Route path="/" element={<RequireAuth loginPath="/login"> 
    <Header
    Loading={Loading} stop={Stop}
     /></RequireAuth>} >
    
    

      <Route path="" element={<RequireAuth loginPath="/login"><Home  loading={Loading} stop={Stop} /></RequireAuth>} />

      <Route path="Profile" element={<RequireAuth loginPath="/login"><Profile  loading={Loading} stop={Stop} /></RequireAuth>} />

      {products&&products.length > 0 ? 
      
      <>
 
     {Number(products&&products[0].read ) > 0 ?

<Route path="About" element={<RequireAuth loginPath="/login"> <About loading={Loading} stop={Stop} /></RequireAuth>} />
       
     :'' }

     {Number(products&&products[1].read ) > 0 ?
      <Route path="Images" element={<RequireAuth loginPath="/login"> <Images loading={Loading} stop={Stop} /></RequireAuth>} />
     :'' } 

     {Number(products&&products[2].read ) > 0 ?
      <Route path="About_us" element={

<RequireAuth loginPath="/login"> <AboutUS loading={Loading} stop={Stop} /></RequireAuth>} />
     :'' } 
     {Number(products&&products[3].read ) > 0 ?
      <Route path="Activities" element={<RequireAuth loginPath="/login"> <Activity  loading={Loading} stop={Stop} /> </RequireAuth>} />
     :'' }
    
     
     {Number(products&&products[4].read ) > 0 ?
      <Route path="Agencies" element={<RequireAuth loginPath="/login"> <Agencies loading={Loading} stop={Stop} /></RequireAuth>} />
     :'' } 

     {Number(products&&products[5].read ) > 0 ?
      <Route path="Branches" element={<RequireAuth loginPath="/login"> <Branches loading={Loading} stop={Stop}/> </RequireAuth>} />
     
     :'' } 
       
     {
  Number(check) > 0 ?
  
  <>
  <Route path="Accounts" element={<RequireAuth loginPath="/login"><Accounts  loading={Loading} stop={Stop} /></RequireAuth>} />
  <Route path="Roles" element={<RequireAuth loginPath="/login"><Roles  loading={Loading} stop={Stop} /></RequireAuth>} />
  </> 
  
  : ''

  
 
     }   





       </>
 :''} 
         </Route>
         
  

    
         </Routes>



         
        }  
         </div> 
    
);
};

export default App;
