import React, { forwardRef, useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useAuthUser } from "react-auth-kit";



const About = ({loading, stop}) => {

  
     
    const auth = useAuthUser()

    const key = auth().key; 
      
    const username = auth().username;

  useEffect( () => {
    getData()
  
   },[]);


  
  
      const [data, setData] = useState({
          about:'',
          subtext:'',
          about_:'',
          subtext_:'',
        });
        
        
  
  
        const onChangeValue = (e) => {
          setData({
            ...data,
            [e.target.name]:e.target.value
          });
  
  
        
         
          
        } 
        
  
        
  
  
        const getData = async () => {
           
   loading()
     
   try {
    
   
    
   
     
     axios.get(`https://admin.al-hubaishi.com/Api/about.php?key=${key}&username=${username}`)
     .then(res => {
       if(res.data.success === true){
 
      setData({
       about:res.data.datalist.constants[0].value,
       about_:res.data.datalist.constants[0].value_en,
       subtext:res.data.datalist.constants[1].value,
       subtext_:res.data.datalist.constants[1].value_en,
       
      })
    
    
      stop()
          
       }else{
         

         toast.error(`${res.data.msg}`,{duration: 3000,},)
         stop()
          
            
           }  
     
 
     })
  } catch (error) { 
   stop()

   throw error;}      
   

        }
  
  
  
  
  
  
      const update = async(e) => {
      
          e.preventDefault();
       
  
  
          loading()
  
       
  
          try {
          
          
            var formData = new FormData();
            formData.append("username",username); 
            formData.append("key",key); 
         
      
            formData.append("about",data.about);
  
            formData.append("about_",data.about_);
            
            formData.append("subtext",data.subtext);
  
      
  
            formData.append("subtext_",data.subtext_);
          
            
            axios.post(`https://admin.al-hubaishi.com/Api/update_about.php`, formData)
            .then(res => {
              
        
  
  
  
      if(res.data.success === true){
       
            stop()
            getData()

          toast.success(`${res.data.msg}`,{duration: 3000,},)
     
      
      
         
  
      }else{
        stop()
          toast.error(`${res.data.msg}`,{duration: 3000,},)
      }
  
  
  
     
          
            })
         } catch (error) { 
      stop()
      toast.error(`${error}`,{duration: 3000,},)
      throw error;}      
        
    
        
  
  
  
  
      
  
  
  
      }
  
  
  
  
  
  
          
  
  
  
  
  
  
   
  
  
  


return (
 <div className="w-full h-screem pt-[80px] ">


<div className="mb-[10px]
">



  <form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={update}>
      <p className="title1"> المقدمة  </p>
    <p  className="message" > تغيير المقدمة </p>
   
   



   <label  className=" ">
        <textarea dir="rtl" required className="input" id="about" maxLength={2000} name="about" rows={7}  value={data.about} onChange={onChangeValue} placeholder=""  />
        <span className="mt-[140px] ">المقدمة</span>
    </label> 

    
    <label  className=" ">
        <input dir="rtl" maxLength={50} required className="input" id="subtext" name="subtext"  value={data.subtext} onChange={onChangeValue} placeholder=""  />
        <span className=""> المدير التنفيذي </span>
    </label> 
 
<hr></hr>
<p className="title1"> About </p>
<p  className="message" > Change About </p>
   <label  className=" ">
        <textarea required className="input" id="about_" maxLength={2000} name="about_" rows={7}  value={data.about_} onChange={onChangeValue} placeholder=""  />
        <span className="mt-[140px] ">About</span>
    </label> 

    

    <label  className=" ">
        <input maxLength={50} required className="input" id="subtext_" name="subtext_"  value={data.subtext_} onChange={onChangeValue} placeholder=""  />
        <span className=""> CEO </span>
    </label> 
 
    <button className="submit">حفظ</button>

   
</form>








   

</div>




  </div>
);
};

export default About;
