import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
    name:"cart",
    initialState:{
       data:[],
       check:0,

        cart:[],
        qty:1, 
        status:false,
        username:'', 
        key:'', 
    },
    reducers:{

        addData : (state,action) => {
            
                state.data=[];
                state.data=action.payload;
            
            
        },
        addcheck : (state,action) => {
            
            state.check=action.payload.check;
           
        
        
    },
    setStatus : (state,action) => {
       
        state.status=action.payload.status;
     },
       
    }


});


export const {setStatus,addData,addcheck} = cartSlice.actions;

export default cartSlice.reducer;