import axios from "axios";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import toast from "react-hot-toast";
import Modal from "../../Modal";
import { MdClose, MdMoreVert } from "react-icons/md";

const Accounts = ({loading, stop}) => {

  

useEffect( () => {
 
    All_users();
    allRoles()
  }, []); 



  const auth = useAuthUser()

  const key = auth().key; 
    
  const username = auth().username;


  


    const [products, setProducts] = useState([]);

    const All_users = async () => {
    
      loading()
    try {
     
    
     
    
      
      axios.get(`https://admin.al-hubaishi.com/Api/users.php?key=${key}&username=${username}`)
      .then(res => {
        if(res.data.success === true){

        setProducts(res.data.productlist.productdata);
 
        stop()
           
        }else{
          
          stop()
           
                toast.error(`${res.data.msg}`,{duration: 3000,},)
            }  
      

      })
   } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
    
    
    }
    
    



const labels = [

{id:1,name:'المقدمة'},
{id:2,name:'الصور'},
{id:3,name:'عنا'},
{id:4,name:'النشاطات'},
{id:5,name:'الوكالات'},
{id:6,name:'الفروع'},
]








    
const [data, setData] = useState({

  name:'',

  role:'',

});




const onChangeValue = (e) => {
  setData({
    ...data,
    [e.target.name]:e.target.value
  });

  
} 



const add = async(e) => {

  e.preventDefault();
 
  loading()
  try {
      
  
    var formData = new FormData();
    formData.append("username",username); 
    formData.append("key",key); 
 
    formData.append("name",data.name); 


    formData.append("role",data.role); 
    
    axios.post(`https://admin.al-hubaishi.com/Api/adduser.php`, formData)
    .then(res => {
      
 



if(res.data.success === true){

  setData({
      name:'',

      role:'',
         
     })

     
     stop()
           
  toast.success(`${res.data.msg}`,{duration: 3000,},)

  setProducts([]);
  All_users()
 

}else{ 
  stop()
  
  toast.error(`${res.data.msg}`,{duration: 3000,},)
}




  
    })
   } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      

  
  }














const [updatedata, setUpdatedata] = useState({
  id:'',
  name:'',
  role:'',

});


const onChangeValue1 = (e) => {
  setUpdatedata({
    ...updatedata,
    [e.target.name]:e.target.value
  });

  
} 






    const [open, setOpen] = React.useState(false);
 
    const handleClose = () => {
      setIndex(-1)
        setOpen(false);
    };
    
    const handleOpen = () => {
        setOpen(true);
    };
    

 


    const [open1, setOpen1] = React.useState(false);
 
    const handleClose1 = () => {
      setIndex(-1)
        setOpen1(false);
    };
    
    const handleOpen1 = () => {
        setOpen1(true);
    };
    

  const update = async(e) => {
    
    e.preventDefault();
   
    loading()

    try {
        
    
      var formData = new FormData();
      formData.append("username",username); 
      formData.append("key",key); 
      formData.append("id",updatedata.id ); 
      formData.append("name", updatedata.name); 
      formData.append("role", updatedata.role); 
      
      
      axios.post(`https://admin.al-hubaishi.com/Api/Edit_user.php`, formData)
      .then(res => {
        




if(res.data.success === true){
 
    handleClose1()
   
      
    stop()
           
    toast.success(`${res.data.msg}`,{duration: 3000,},)

    setProducts([]);
    All_users();
   

}else{    
  
  stop()

    toast.error(`${res.data.msg}`,{duration: 3000,},)
}




    
      })
  } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
    
    

    


  }
    



  const delete_restore = async(active) => {
    
    
   
    loading()

    try {
        
    
      var formData = new FormData();
      formData.append("username",username); 
      formData.append("key",key); 
      formData.append("id",updatedata.id ); 
      formData.append("active", active); 
      
      
      axios.post(`https://admin.al-hubaishi.com/Api/Active_user.php`, formData)
      .then(res => {
        




if(res.data.success === true){
 
    handleClose1()
   
      
    stop()
           
    toast.success(`${res.data.msg}`,{duration: 3000,},)

    setProducts([]);
    All_users();
   

}else{    
  
  stop()

    toast.error(`${res.data.msg}`,{duration: 3000,},)
}




    
      })
  } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
    
    

    


  }



  const [roles, setRoles] = useState([]);

  const allRoles = async () => {
  
    loading()
  try {
   
  
   
  
    
    axios.get(`https://admin.al-hubaishi.com/Api/Roles.php?key=${key}&username=${username}`)
    .then(res => {
      if(res.data.success === true){

        setRoles(res.data.productlist.productdata);

      stop()
         
      }else{
        
        stop()
         
              toast.error(`${res.data.msg}`,{duration: 3000,},)
          }  
    

    })
 } catch (error) { 
  stop()
  toast.error(`${error}`,{duration: 3000,},)
  throw error;}      
  
  
  }
  





    


    const [index, setIndex] = useState(-1);
      
      


    const [privillages, setPrivillages] = useState([]);
    
    

    

return (
  <div className="pt-[60px]">




<h1> حسابات المستخدمين </h1>







<div className="mb-[150px] mt-[50px] ">



<form className="form1 w-[80%] max-w-[1000px]  m-auto"  onSubmit={add}>
      <p className="title1"> اضافة حساب  </p>
    <p  className="message" dir="rtl"> ملاحظة: اسم المستخدم تتكون من احرف انجليزية وارقام فقط وبدون فراغات </p>
       
   
    <label className="">
        <input maxLength={20}  pattern="[a-zA-Z0-9]+" className="input" id="name" name="name" value={data.name} onChange={onChangeValue} type="text" placeholder="" required/>
        <span> اسم المستخدم </span>
    </label> 
  
   
    <label className="">
        <select maxLength={20}  pattern="[a-zA-Z0-9]+" className="input" id="role" name="role" value={data.role} onChange={onChangeValue} type="text" placeholder="" required >

        <option  ></option>
{roles.map((item,i) => 


<option value={item.id} >{item.name}</option>


)}

        </select>
        <span>  الوظيفة </span>
    </label> 


    <button className="submit">أضافة</button>

   
</form>
<hr></hr>

<div  className= {`flex mt-2  `}>



 <div dir="rtl" style={{placeContent:"center" , verticalAlign:'middle'}}  
 
 className={` m-auto flex max-w-[1000px] w-[80%] mb-2 p-2 rounded bg-gray-300`} > 



 
 
<div style={{placeContent:"center" , verticalAlign:'middle'}} 
className= {`w-[10%]  `} >
    <div className="font-bold text-[15px] "> 
      <p style={{wordBreak:'break-word'}}>id</p>
    </div>
  </div>




  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[50%] ">
    <div className="font-bold text-[15px] "> 
      <p  style={{wordBreak:'break-word'}}>اسم مستخدم</p>
    </div>
  </div>


  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[30%] ">
    <div className="font-bold text-[15px] "> 
      <p style={{wordBreak:'break-word'}}>وظيفة</p>
    </div>
  </div>






  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[10%] ">
    <div className="font-bold text-[15px] "> 
      <p style={{wordBreak:'break-word'}}>...</p>
    </div>
  </div>



    </div>
</div>

{products.map((item,i) => 

<>
<div  className= {`flex mt-2  `}>



 <div dir="rtl" style={{placeContent:"center" , verticalAlign:'middle'}}  
 
 className={` m-auto flex max-w-[1000px] w-[80%] mb-2 p-2 rounded bg-gray-300 `} > 



 
 
<div style={{placeContent:"center" , verticalAlign:'middle'}} 
className= {`w-[10%]  `} >
    <div className="font-bold text-[15px] "> 
      <p style={{wordBreak:'break-word'}}>{item.id} </p>
    </div>
  </div>




  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[50%] ">
    <div className="font-bold text-[15px] "> 
      <p className={`${ Number(item.id) > 1 ? Number(item.active) > 0 ? "text-green-500" : 'text-red-500' :'text-black text-[20px]' }`} style={{wordBreak:'break-word'}}>{item.username}  </p>
    </div>
  </div>


  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[30%] ">
    <div className="font-bold text-[15px] "> 
      <p style={{wordBreak:'break-word'}}>{item.role} </p>
    </div>
  </div>



  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[10%] ">

  <button onClick={()=>{
     
    
    if(index===i){
        setIndex(-1)
        setUpdatedata({
          id:'',
          name:'',
          role:''
        })
    
    }else{

      setUpdatedata({
          id:item.id,
          name:item.username,
          role:item.role_id,
        })
     
        setIndex(i)
    }
    
  }} >
       
 {Number(item.id) === 1 ?  ''  :   
       
            index===i ?  <MdClose color="grey" size={30} />
            :<MdMoreVert color="grey" size={30} />}
            </button>
          { index===i ? <div className="dropdown">




{Number(item.active) > 0 ? 
            <div className="dropdown-content">

<button className="p-2 bg-[#dbdbdb]  w-[120px] rounded hover:opacity-70 m-1" onClick={()=>{
handleOpen1()
}} >
تعديل
</button>
<br />
{ Number(item.id) === 1  ? '' :
<button className="p-2 bg-[#dbdbdb] text-red-500 w-[120px] rounded hover:opacity-70 m-1" onClick={()=>{
delete_restore(0)
}} >
الغاء التفعيل
</button>
}
</div>
  
:
<div className="dropdown-content">


<button className="p-2 bg-[#dbdbdb]  w-[120px] rounded hover:opacity-70 m-1" onClick={()=>{

handleOpen1()
}} >
تعديل
</button>
<br />
<button className="p-2 bg-[#dbdbdb] text-green-500  w-[120px] rounded hover:opacity-70 m-1" onClick={()=>{
delete_restore(1)
}} >
تفعيل
</button>

</div>

}


</div>:''}
 
 </div>




    </div>
</div>

<hr />
</>

)}




 
<hr></hr>
    </div>


    <Modal isOpen={open1} onClose={handleClose1}>
                <>
           

<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={update}>
  
    <p className="title1"> تحديث حساب {updatedata.id}  </p>
    <p  className="message" dir="rtl"> ملاحظة: اسم المستخدم تتكون من احرف انجليزية وارقام فقط وبدون فراغات </p>
       
   
    <label className="">
        <input maxLength={20}  pattern="[a-zA-Z0-9]+" className="input" id="name" name="name" value={updatedata.name} onChange={onChangeValue1} type="text" placeholder="" required/>
        <span> اسم المستخدم </span>
    </label> 
  
   
    <label className="">
        <select maxLength={20}  pattern="[a-zA-Z0-9]+" className="input" id="role" name="role" value={updatedata.role} onChange={onChangeValue1} type="text" placeholder="" required >

     
{roles.map((item,i) => 


<option value={item.id} >{item.name}</option>


)}

        </select>
        <span>  الوظيفة </span>
    </label> 
    
    <button className="submit">تحديث</button>

   
</form>
<hr></hr>


                </>
            </Modal>




   
       





  </div>
);
};

export default Accounts;
