import React, { forwardRef, useEffect, useRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useAuthUser } from "react-auth-kit";
import { MdAddCircle, MdClose, MdDelete, MdDeleteSweep, MdMoreVert, MdSave } from "react-icons/md";
import { activity } from "../data";
import {
    VerticalTimeline,
    VerticalTimelineElement,
  } from "react-vertical-timeline-component";
  
  import "react-vertical-timeline-component/style.min.css";
import Modal from "../../Modal";
  


const Branches = ({loading, stop}) => {

  
     
    const auth = useAuthUser()

    const key = auth().key; 
      
    const username = auth().username;

  useEffect( () => {
   getData()
  
   },[]);


  
  
   const [data, setData] = useState({
    title:'',

    title_:'',


  });
  
  
  
  
  const [data_, setData_] = useState({
    title:'',
    title_:'',
    location:'',

  });
  
  
        const onChangeValue = (e) => {
          setData({
            ...data,
            [e.target.name]:e.target.value
          });
  
          
        } 
        
  

        const onChangeValue1 = (e) => {
            setData_({
              ...data_,
              [e.target.name]:e.target.value
            });
    
    
          } 
          
        










        const [agents, setAgents] = useState([])
  
        const getData = async () => {
           
   loading()
     
   try {
    
     
    
   
     
     axios.get(`https://admin.al-hubaishi.com/Api/Branches.php?key=${key}&username=${username}`)
     .then(res => {
       if(res.data.success === true){
 
     
        setAgents(res.data.datalist.region)
    
      stop()
          
       }else{
         

         toast.error(`${res.data.msg}`,{duration: 3000,},)
         stop()
          
            
           }  
     
 
     })
  } catch (error) { 
   stop()

   throw error;}      
   

        }
  
  
  
        const Add = async(e) => {
      
            e.preventDefault();
         
       
            if(added){
                toast.loading(`يرحى الأنتظار حتى يتم معالجة الطلب السابق`,{duration: 3000,},)
              }else{



           
    
            loading()
    
         
     
            try {
            
            
              var formData = new FormData();
              formData.append("username",username); 
              formData.append("key",key); 
           
              formData.append("value",data.title);
              formData.append("value_",data.title_);

           
            
              
              axios.post(`https://admin.al-hubaishi.com/Api/add_region.php`, formData)
              .then(res => {
                
          
    
    
    
        if(res.data.success === true){
          setData({
          title:'',
       
          title_:'',
        

         })
         setAdded(false)
              stop()
              getData()
  
            toast.success(`${res.data.msg}`,{duration: 3000,},)
       
        
        
           
    
        }else{
            setAdded(false)
          stop()
            toast.error(`${res.data.msg}`,{duration: 3000,},)
        }
    
    
    
       
            
              })
           } catch (error) { 
            setAdded(false)
        stop()

        toast.error(`${error}`,{duration: 3000,},)
        throw error;}      
          
      
    }
        }
    
     
     
  
  
      const update = async(id,i) => {
      
          //e.preventDefault();
       
  
          loading()
    
         
     
          try {
          
          
            var formData = new FormData();
            formData.append("username",username); 
            formData.append("key",key); 
         
            formData.append("id",id);
            formData.append("value",agents[i].value);
            formData.append("value_",agents[i].value_en);
           
          
            
            axios.post(`https://admin.al-hubaishi.com/Api/Edit_region.php`, formData)
            .then(res => {
              
        
  
  
      if(res.data.success === true){
       
            stop()
            getData()

          toast.success(`${res.data.msg}`,{duration: 3000,},)
     
      
      
         
  
      }else{
        stop()
          toast.error(`${res.data.msg}`,{duration: 3000,},)
      }
  
  
  
     
          
            })
         } catch (error) { 
      stop()
      toast.error(`${error}`,{duration: 3000,},)
      throw error;}      
        
    
  
      }
  







      const update_ = async(id,index,i) => {
      
        //e.preventDefault();
     

        loading()
  
       
   
        try {
        
        
          var formData = new FormData();
          formData.append("username",username); 
          formData.append("key",key); 
       
          formData.append("id",id);
          formData.append("value",agents[index].branches[i].value);
          formData.append("value_",agents[index].branches[i].value_en);
          formData.append("location",agents[index].branches[i].location);
        
          
          axios.post(`https://admin.al-hubaishi.com/Api/Edit_Branch.php`, formData)
          .then(res => {
            
      


    if(res.data.success === true){
     
          stop()
          getData()

        toast.success(`${res.data.msg}`,{duration: 3000,},)
   
    
    
       

    }else{
      stop()
        toast.error(`${res.data.msg}`,{duration: 3000,},)
    }



   
        
          })
       } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
      
  

    }






    const Add_phone = async(e) => {
      
        e.preventDefault();
     handleClose()
   
        if(added){
            toast.loading(`يرحى الأنتظار حتى يتم معالجة الطلب السابق`,{duration: 3000,},)
          }else{
            loading()
  
       
   
            try {
            
            
              var formData = new FormData();
              formData.append("username",username); 
              formData.append("key",key); 
           
              formData.append("id",bid);
              formData.append("phone",no);
            
              
              axios.post(`https://admin.al-hubaishi.com/Api/Add_Branch_phone.php`, formData)
              .then(res => {
                
          
    
    
        if(res.data.success === true){
            setAdded(false)
              stop()
              getData()
    
            toast.success(`${res.data.msg}`,{duration: 3000,},)
       
        
        
           
    
        }else{
            setAdded(false)
          stop()
            toast.error(`${res.data.msg}`,{duration: 3000,},)
        }
    
    
    
       
            
              })
           } catch (error) { 
            setAdded(false)
        stop()
        toast.error(`${error}`,{duration: 3000,},)
        throw error;}      
          
          }
      
  

    }




      
    const del = async(id) => {
      
      //e.preventDefault();
   

      loading()

     
 
      try {
      
      
        var formData = new FormData();
        formData.append("username",username); 
        formData.append("key",key); 
     
        formData.append("id",id);
       
     
      
        
        axios.post(`https://admin.al-hubaishi.com/Api/delete_region.php`, formData)
        .then(res => {
          
    


  if(res.data.success === true){
   
        stop()
        getData()

      toast.success(`${res.data.msg}`,{duration: 3000,},)
 
  
  
     

  }else{
    stop()
      toast.error(`${res.data.msg}`,{duration: 3000,},)
  }



 
      
        })
     } catch (error) { 
  stop()
  toast.error(`${error}`,{duration: 3000,},)
  throw error;}      

  }








   
  const del_phone = async(id) => {
      
    //e.preventDefault();
 handleClose()

    loading()

   

    try {
    
    
      var formData = new FormData();
      formData.append("username",username); 
      formData.append("key",key); 
   
      formData.append("id",id);
     
   
    
      
      axios.post(`https://admin.al-hubaishi.com/Api/delete_phone.php`, formData)
      .then(res => {
        
  


if(res.data.success === true){
 
      stop()
      getData()

    toast.success(`${res.data.msg}`,{duration: 3000,},)



   

}else{
  stop()
    toast.error(`${res.data.msg}`,{duration: 3000,},)
}




    
      })
   } catch (error) { 
stop()
toast.error(`${error}`,{duration: 3000,},)
throw error;}      

}







      const [added, setAdded] = useState(false);  
  

      const Add_ = async(id) => {
      
        //e.preventDefault();
     
if(added){
  toast.loading(`يرحى الأنتظار حتى يتم معالجة الطلب السابق`,{duration: 3000,},)
}
else if (data_.title === '' || data_.title_ === ''){
  toast.error(`يرحى ادخال البيانات`,{duration: 3000,},)
}else{



  setAdded(true)
  loading()

 

  try {
  
  
    var formData = new FormData();
    formData.append("username",username); 
    formData.append("key",key); 
 
    formData.append("id",id);
    formData.append("value",data_.title);
    formData.append("value_",data_.title_);
   
    formData.append("location",data_.location);
  
    
    axios.post(`https://admin.al-hubaishi.com/Api/Add_Branch.php`, formData)
    .then(res => {
      



if(res.data.success === true){
setData_({
  title:'',
title_:'',
location:'',
})
setAdd(-1)
    stop()
    getData()

  toast.success(`${res.data.msg}`,{duration: 3000,},)

  setAdded(false)

 

}else{
stop()
  toast.error(`${res.data.msg}`,{duration: 3000,},)
}




  
    })
 } catch (error) { 
stop()
setAdded(false)
toast.error(`${error}`,{duration: 3000,},)
throw error;}      



}
  

    



    }





  
    const del_ = async(id) => {
      
      //e.preventDefault();
   

      loading()

     
 
      try {
      
      
        var formData = new FormData();
        formData.append("username",username); 
        formData.append("key",key); 
     
        formData.append("id",id);
       
     
      
        
        axios.post(`https://admin.al-hubaishi.com/Api/delete_branch.php`, formData)
        .then(res => {
          
    


  if(res.data.success === true){
   
        stop()
        getData()

      toast.success(`${res.data.msg}`,{duration: 3000,},)
 
  
  
     

  }else{
    stop()
      toast.error(`${res.data.msg}`,{duration: 3000,},)
  }



 
      
        })
     } catch (error) { 
  stop()
  toast.error(`${error}`,{duration: 3000,},)
  throw error;}      
    

    




  



  }




  const [add, setAdd] = useState(-1);

  const [open, setOpen] = useState(false);
 
  const handleClose = () => {
    setI(-1)
            setShow(-1)
            setPhones([])
            setBid(0)
            setNo()
      setOpen(false);
  };
  
  const handleOpen = () => {
      setOpen(true);
  };
  


  const [bid, setBid] =useState(0);  
 
  const [no, setNo] =useState();  
 

  const [phones, setPhones] =useState([]);  
 
  
const [i, setI] =useState(-1);  
 
  
  
          
  
  
const [show, setShow] = useState(-1);  
  
  



function isEven(n) {
    return (n % 2 == 0);
  }
  
  
  

return (
 <div className="w-full h-screem pt-[80px] ">


<div className="mb-[10px]
">





<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={Add}>
 
 



 

 <p className="title1"> المنطقة  </p>
 
 

     <p  className="message" > عربي </p>
     <label  className=" ">
         <input dir="rtl" maxLength={50} required className="input" id="title" name="title"  value={data.title} onChange={onChangeValue} placeholder=""  />
         <span className=""> المنطقة </span>
     </label> 
  
 
  
     <p  className="message" > English </p>
     <label  className=" ">
         <input maxLength={50} required className="input" id="title_" name="title_"  value={data.title_} onChange={onChangeValue} placeholder=""  />
         <span className=""> Region </span>
     </label> 
  
   
     <hr></hr>
    
     
     <button className="submit">أضافة</button>
 
    
 </form>
 






   

</div>



  
<hr></hr>
<div className="m-4">
  {agents.map((item,index) => 
<>
   <div className="pt-3 rounded" style={{background:`${isEven(index) ? '#E6DEDD':'#dbdbdb'}`}}>



<button className="absolute right-7 z-1 " onClick={()=>{
          
          

          confirmAlert({
                  title: 'Confirm to Submit',
                  message:` هل أنت متأكد ، تريد حذف هذه المنطقة ؟  `,
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: () => {
                        del(item.id)
          
          }
                    },
                    {
                      label: 'No',
                      onClick: () => { }
                    }
                  ]
                });
          
                    
                   }} ><MdDeleteSweep className="" color="red" size={25}/>

                    
                   </button> 

<br></br>
    <div style={{background:'transparent'}}  className="form1  text-black">
    <p className="title1"> المنطقة {index+1} </p>
      <div  className=" " >
      <div dir="rtl" className="flex">
      
       
     <label  className="w-full">
         <input required dir="rtl" placeholder=""  type=""  value={agents[index]?.value}  onChange={(e)=>{
            
        var new_arr = [...agents]; 
      
      new_arr[index].value = e.target.value;
      
      setAgents(new_arr);
       
  //    toast.success(payments[i]?.note)
         }} className="input"/>
         <span> المنطقة </span>
     </label>

     <label  className="w-full  ">
         <input  required placeholder=""  type=""  value={agents[index]?.value_en}  onChange={(e)=>{
            
        var new_arr = [...agents]; 
      
      new_arr[index].value_en = e.target.value;
      
      setAgents(new_arr);
       
  
         }} className="input"/>
         <span>Region</span>
     </label>
          </div>
        
      
      </div>

     
      
     <button 
     
     onClick={()=>update(item.id,index)} className="submit">تحديث</button>
           </div>

           <hr />
           <br></br>
           <div className="flex">
           <p className="title1 m-auto"> الفروع  </p>
           </div>
       
{item.branches.map((branch, indx) => (
    <div style={{background:'transparent'}}  className="form1 text-black">
 <button className="absolute top-0 left-5" onClick={()=>{
          if(i===index && show === indx){
            setI(-1)
            setShow(-1)
            setPhones([])
            setBid(0)
          }else{
            setI(index)
            setShow(indx)
            setBid(branch.id)
            setPhones(branch.phones)
          }
        

                    
                   }} >

{ show===indx && i === index ?
    <MdClose  className="m-auto" color="black" size={30}  />
:
  <MdMoreVert className="m-auto" color="black" size={30} />
}
  </button> 
          
  { show===indx && i === index ? <div className="dropdown">



<div className="dropdown-content">


<button className={` text-black p-2 bg-[#dbdbdb] w-[120px] rounded hover:opacity-70 m-1`} onClick={()=>{

 
     handleOpen()         

}} >
    رقم هاتف
          </button>

  <button className={`text-red-500 p-2 bg-[#dbdbdb] w-[120px] rounded hover:opacity-70 m-1`} onClick={()=>{

 
confirmAlert({
                  title: 'Confirm to Submit',
                  message:` هل أنت متأكد ، تريد حذف الفرع ؟  `,
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: () => {
                        del_(branch.id)
          
          }
                    },
                    {
                      label: 'No',
                      onClick: () => { }
                    }
                  ]
                });
              

}} >
    حذف
          </button>
</div>

</div>
:''
  }

    <div dir="rtl" className="flex mt-3">
    
       
      <label  className="w-full ">
          <input required dir="rtl" placeholder=""  type=""  value={agents[index].branches[indx]?.value}  onChange={(e)=>{
             
         var new_arr = [...agents]; 
       
       new_arr[index].branches[indx].value = e.target.value;
       
       setAgents(new_arr);
        

          }} className="input"/>
          <span> الفرع </span>
      </label>
 
      <label  className="w-full ">
          <input  required placeholder=""  type=""  value={agents[index].branches[indx]?.value_en}  onChange={(e)=>{
             
             
             var new_arr = [...agents]; 
       
       new_arr[index].branches[indx].value_en = e.target.value;
       
       setAgents(new_arr);
        

          }} className="input"/>
          <span>Branch</span>
      </label>

     
        
          
           </div>
           <label  className="w-full m-1 ">
          <input  required placeholder=""  type=""  value={agents[index].branches[indx]?.location}  onChange={(e)=>{
             
             
             var new_arr = [...agents]; 
       
       new_arr[index].branches[indx].location = e.target.value;
       
       setAgents(new_arr);
        

          }} className="input"/>
          <span>رابط الموقع</span>
      </label>
           <div className=" p-2  " >
           
           <MdSave className='m-auto cursor-pointer' 
           onClick={()=>{update_(branch.id,index,indx)}}  
           color="steelblue" size={25}/>
           
           </div> 
           





<hr  />
<br />


           </div>
        ))}

         
       
{add === index ?

   
    <div style={{background:'transparent'}} className="form1 text-black w-[80%] max-w-[1000px]  m-auto "  >
   <MdClose className="cursor-pointer" onClick={()=>{
    setData_({
  title:'',
  title_:'',
  location:'',
})
    setAdd(-1)}} size={30} />
 

   <p className="title1"> اضافة فرع
     </p>
   
   
  
       <p  className="message" > عربي </p>
       <label  className=" ">
           <input dir="rtl" maxLength={50} required className="input" id="title" name="title"  value={data_.title} onChange={onChangeValue1} placeholder=""  />
           <span className=""> الاسم </span>
       </label> 
   
       
     
       <p  className="message" > English </p>
       <label  className=" ">
           <input maxLength={50} required className="input" id="title_" name="title_"  value={data_.title_} onChange={onChangeValue1} placeholder=""  />
           <span className=""> name </span>
       </label> 
  
  <hr />
       <label  className=" ">
       <input   placeholder=""  id="location" name="location"  type=""  value={data_.location}  onChange={onChangeValue1} className="input"/>
            <span>رابط الموقع</span>
        </label>
        <div className=" p-2  " >
             
             <MdAddCircle className='m-auto cursor-pointer' 
           onClick={()=>Add_(item.id)}
             color="steelblue" size={25}/>
             
             </div> 
  
      
   
      
   </div>
   
:


<button className="mb-10  mt-5" onClick={()=>{
      setData_({
  title:'',
  title_:'',
  location:'',
})  
    setAdd(index)}} >اضافة فرع ؟</button>

}
 


 </div>


 <br />
<hr />

<br />
</>
)}



</div>




<br />










<Modal isOpen={open} onClose={handleClose}>
<>
<ol>
{phones.map((item,i) =>
<div className="flex">
<li className="m-auto flex p-2">{i+1}) &ensp; {item.phone} &ensp; <MdDelete onClick={()=>del_phone(item.id)} className="cursor-pointer" color="red" size={25}/> </li>

</div>

)}
</ol>



<hr></hr>
<form style={{background:'transparent'}} className="form1 text-black w-[80%] max-w-[1000px]  m-auto " onSubmit={Add_phone}  >
   
 
 <p className="title1"> اضافة هاتف فرع </p>
 
<hr />
     <label  className=" ">
     <input  required placeholder=""   type="tel"  value={no}  onChange={(e)=> setNo(e.target.value)} className="input"/>
          <span>رقم الهاتف</span>
      </label>


    <button className="submit" >اضافة</button>
 
    
 </form>
 



 


</>
</Modal>





    </div>






);
};

export default Branches;
