import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import axios from 'axios';
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "../store/reducers/CartReducer";
import { MdOutlineSettings } from "react-icons/md";

import { useSignIn } from "react-auth-kit";
import {useIsAuthenticated} from 'react-auth-kit';
import Modal from "../Modal";
import Loader from "../Loader";

const Login = ({privillage, loading,stop}) => {

const isAuthenticated = useIsAuthenticated()

const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);

  const handleClose = () => setShowMenu(false);

 const signIn = useSignIn()

  useEffect( () => {
   
  if(isAuthenticated()){
    navigate(`/`);
  }
  else {
    navigate(`/login`);
  }

 },[]); 


   

 const [userInfo, setuserInfo] = useState({
  username: '',
 
  password: '',
});
 
 const onChangeValue = (e) => {
   setuserInfo({
     ...userInfo,
     [e.target.name]:e.target.value
   });
 } 

 
 const [login, setLogin] = useState(false)
 
 
 const loginUser = async(event) => {
 

  if(login){

  }else{
    setLogin(true)

    toast.loading('Authenticating...')
  

    try {
 
    
 
      event.preventDefault();
      event.persist();
      
 
 
      axios.get(`https://admin.al-hubaishi.com/Api/signin.php?username=${userInfo.username}&password=${userInfo.password}` )
      .then(res => {
       
 
       if(res.data.success===true){
 
 
 
         toast.remove()
 setLogin(false)
       
 
  
 
 
       if (signIn({
           token: res.data.key,
           expiresIn: 1440,
           tokenType: "Bearer",
           authState: { username:res.data.username,key:res.data.key},
         })){
 
           privillage(res.data.username,res.data.key);
 
        // navigate(`/`);
 
       }else {
         toast.error('error')
       }
 
       }else{
        
      toast.remove();
      setLogin(false)
        toast.error(res.data.msg)
       }
        
        
       })
 
    } catch (error)
    
    {
      toast.remove();
      setLogin(false)
      throw error;
    
    } 
  

  }

 };






   
 const sendCode_ = async() => {

            
  loading()

try {



 
 var formData = new FormData();
 formData.append("username",userInfo.username); 



 
  axios.post(`https://admin.al-hubaishi.com/Api/verficationCode(1).php`,formData )
  .then(res => {
   

   if(res.data.success===true){
     toast.success(res.data.msg,{duration: 3000,},);
  
//     userinfo()
handleOpen1()

   }else{
      stop()

    toast.error(res.data.msg,{duration: 3000,},);
   }
    
    
   })

  } catch (error) { 
      stop()
 toast.error(`${error}`,{duration: 3000,},)
 throw error;}   





};

const verfiy_ = async(e) => {

  e.preventDefault();
  loading()

try {



 
 var formData = new FormData();
 formData.append("username",userInfo.username); 

 formData.append("code",code);

 
  axios.post(`https://admin.al-hubaishi.com/Api/checkver_active(1).php`,formData )
  .then(res => {
   

   if(res.data.success===true){
    stop()
     toast.success(res.data.msg,{duration: 8000,},);
  
 handleClose1()
   

   }else{
      stop()

    toast.error(res.data.msg,{duration: 3000,},);
   }
    
    
   })

  } catch (error) { 
      stop()
 toast.error(`${error}`,{duration: 3000,},)
 throw error;}   





};





  const [code, setCode] = useState('');

//        const [count, setCount] = useState('');


const [open1, setOpen1] = useState(false);

const handleClose1 = () => {
setOpen1(false);
setCode('')
};

const handleOpen1 = () => {
setOpen1(true);
};




const Loading = useSelector((state) => state.cart.status);
 

return (




<div  className="flex min-h-[100%]">

{
  Loading ?<Loader /> : ''
}

<header style={{zIndex:10}} className=" fixed w-full flex flex-row items-center justify-between sm:justify-around p-2 border-b-2 bg-gray-100">
      <button
      onClick={handleClose} 
       
   className=" font-bold items-center py-2 px-4 bg-gradient-to-b from-[#c73e3e]/90  rounded-tr-full rounded-bl-full w-[200px]  uppercase hover:opacity-70"
      >
            Al-hubaishi
      </button>
    
      <nav className=" flex flex-col items-end gap-1 font-semibold ">
        <button
          onClick={() => setShowMenu(!showMenu)}
          className=" font-bold text-xl hover:text-gray-500"
        >
          {showMenu ? <GrClose /> : <GiHamburgerMenu />}
        </button>
        {showMenu && (
          <>
          <Link onClick={handleClose}  className="hover:text-gray-500" to={'/login'} >Login</Link>
        
          </>
        )}
      </nav>
    </header>

<form className="form min-w-[300px]" onSubmit={loginUser}>
      <p className="title1">تسجيل دخول </p>
    <p className="message"> أكتب اسم المستخدم وكلمة السر للدخول  </p>
       
            
    <label>
        <input className="input" id="username" name="username" onChange={onChangeValue} type="text" placeholder="" required/>
        <span>اسم المستخدم</span>
    </label> 
        
    <label>
        <input className="input" id="password" name="password" onChange={onChangeValue} type="password" placeholder="" required/>
        <span>كلمة السر</span>
    </label>
   
    <button className="submit">Submit</button>

  <p className="signin cursor-pointer"> <a onClick={()=>{
      
      if(  userInfo.username !== ''){
        sendCode_()
      }else{
        toast.error(' اكتب اسم المستخدم ',{duration: 3000,},);
      }
    
  }}> لقد نسيت كلمة السر ؟ </a> </p>
</form>












<Modal isOpen={open1} onClose={handleClose1}>
<>





<form className="form1 w-[80%] max-w-[1000px]  m-auto"  onSubmit={verfiy_} >
 
 



 

 <p className="title1">   رمز التحقق  </p>
 <p className="message"> سيتم تغيير كلمة السر الى (123456789) </p>
       

 <label className="">
        <input maxLength={9}  className="input" id="code" name="code" value={code} onChange={(e)=>setCode(e.target.value)} type="text" placeholder="" required/>
        <span>  رمز التحقق    </span>
  
  
     </label> 
 
     <button className="submit">تهيئة</button>

   
</form>
 

</>
</Modal>

</div>


);
};

export default Login;
