import React, { forwardRef, useEffect, useRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useAuthUser } from "react-auth-kit";
import { MdClose, MdDelete, MdMoreVert } from "react-icons/md";
import { activity } from "../data";
import Modal from "../../Modal";



const Agencies = ({loading, stop}) => {

  
     
    const auth = useAuthUser()

    const key = auth().key; 
      
    const username = auth().username;

  useEffect( () => {
   getData()
  
   },[]);


  
  
      const [data, setData] = useState({
          title:'',
          description:'',
          title_:'',
          description_:'',
          home:'0',
          category:'',
        });
        
        
  
  
        const onChangeValue = (e) => {
          setData({
            ...data,
            [e.target.name]:e.target.value
          });
   
          
        } 
        
  



        const [updatedata, setUpdateData] = useState({
         id:'',
          title:'',
          description:'',
          title_:'',
          description_:'',
          home:'0',
          category:'',
          src:'',
        });
        


        const onChangeValue1 = (e) => {
          setUpdateData({
            ...updatedata,
            [e.target.name]:e.target.value
          });
   
          
        } 
        






        
        const [agents, setAgents] = useState([])
  
        const getData = async () => {
           
   loading()
     
   try {
    
   
    
   
     
     axios.get(`https://admin.al-hubaishi.com/Api/agents.php?key=${key}&username=${username}`)
     .then(res => {
       if(res.data.success === true){
 
     
        setAgents(res.data.datalist.productdata)
    
      stop()
          
       }else{
         

         toast.error(`${res.data.msg}`,{duration: 3000,},)
         stop()
          
            
           }  
     
 
     })
  } catch (error) { 
   stop()

   throw error;}      
   

        }
  
  
  
        const Add = async(e) => {
      
            e.preventDefault();
         
    
    
            loading()
    
         
     
            try {
            
            
              var formData = new FormData();
              formData.append("username",username); 
              formData.append("key",key); 
           
              formData.append("title",data.title);
              formData.append("title_",data.title_);
              formData.append("description",data.description);
              formData.append("description_",data.description_);
              formData.append("alt",`وكيل ${data.title} ${data.title_} في اليمن`);
              formData.append("photo",file);
              formData.append("category",data.category);
              formData.append("homescreen",data.homescreen);
            
              
              axios.post(`https://admin.al-hubaishi.com/Api/add_agent.php`, formData)
              .then(res => {
                
          
    
    
    
        if(res.data.success === true){
          setData({
          title:'',
          description:'',
          title_:'',
          description_:'',
          home:'0',
          category:'',
         })
         setFile('')
         handleReset()
              stop()
              getData()
  
            toast.success(`${res.data.msg}`,{duration: 3000,},)
       
        
        
           
    
        }else{
          stop()
            toast.error(`${res.data.msg}`,{duration: 3000,},)
        }
    
    
    
       
            
              })
           } catch (error) { 
        stop()
        toast.error(`${error}`,{duration: 3000,},)
        throw error;}      
          
      
          
    
    
    
    
        
    
    
    
        }
    
     
  
        const Add_ = async(e) => {
      
          e.preventDefault();
       
  
  
          loading()
  
          toast.loading(`Uploading...`)
   
          try {
          
          
            var formData = new FormData();
            formData.append("username",username); 
            formData.append("key",key); 
         
         
            formData.append("file",file);
            formData.append("agent_fid",fid);
            
            axios.post(`https://admin.al-hubaishi.com/Api/add_agent_products.php`, formData)
            .then(res => {
              
        
  
  
  
      if(res.data.success === true){
       toast.remove()
       setFile('')
       handleReset()
            stop()
            getData()

          toast.success(`${res.data.msg}`,{duration: 3000,},)
     
      
      
         
  
      }else{
        stop()
          toast.error(`${res.data.msg}`,{duration: 3000,},)
      }
  
  
  
     
          
            })
         } catch (error) { 
      stop()
      toast.error(`${error}`,{duration: 3000,},)
      throw error;}      
        
    
    
  
      }
  
  

      const del = async(id) => {
      
       
     
     loading()

     

     

        try {
        
        
          var formData = new FormData();
          formData.append("username",username); 
          formData.append("key",key); 
       
    
    

          formData.append("id",id);
        
          
          axios.post(`https://admin.al-hubaishi.com/Api/delete_agent.php`, formData)
          .then(res => {
            
      



            if(res.data.success === true){
             stop()
              setFile('')
              handleReset()
                  
                   getData()
       
                 toast.success(`${res.data.msg}`,{duration: 3000,},)
            
             
             
                
            }else{
              stop()
              toast.error(`${res.data.msg}`,{duration: 3000,},)

            }
         
              
          
         
         

   
        
          })
       } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
      
  
      




    



    }



      const delete_ = async(id) => {
      
       
     
        toast.loading(`Deleting...`)

     

     

        try {
        
        
          var formData = new FormData();
          formData.append("username",username); 
          formData.append("key",key); 
       
    
    

          formData.append("id",id);
        
          
          axios.post(`https://admin.al-hubaishi.com/Api/delete_agent_product.php`, formData)
          .then(res => {
            
      



            if(res.data.success === true){
              toast.remove()
              setFile('')
              handleReset()
                  
                   getData()
       
                 toast.success(`${res.data.msg}`,{duration: 3000,},)
            
             
             
                
            }else{
              toast.remove()
              toast.error(`${res.data.msg}`,{duration: 3000,},)

            }
         
              
          
         
         

   
        
          })
       } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
      
  
      




    



    }





      const update = async(e) => {
      
          e.preventDefault();
       
  handleClose1()
  
          loading()
  
       
  
          try {
          
          
          
            var formData = new FormData();
            formData.append("username",username); 
            formData.append("key",key); 
            
            formData.append("id",updatedata.id);
            formData.append("title",updatedata.title);
            formData.append("title_",updatedata.title_);
            formData.append("description",updatedata.description);
            formData.append("description_",updatedata.description_);
            formData.append("alt",`وكيل ${updatedata.title} ${updatedata.title_} في اليمن`);
          
            formData.append("category",updatedata.category);
            formData.append("homescreen",updatedata.home);
          
            
            axios.post(`https://admin.al-hubaishi.com/Api/Edit_agent.php`, formData)
            .then(res => {
              
        
  
  
  
      if(res.data.success === true){
       
            stop()
            getData()

          toast.success(`${res.data.msg}`,{duration: 3000,},)
     
      
      
         
  
      }else{
        stop()
          toast.error(`${res.data.msg}`,{duration: 3000,},)
      }
  
  
  
     
          
            })
         } catch (error) { 
      stop()
      toast.error(`${error}`,{duration: 3000,},)
      throw error;}      
        
    
        
  
  
  
  
      
  
  
  
      }
  
  
  
  
  
  

      const update_img = async(e) => {
      
        e.preventDefault();
     
        handleClose1()

        loading()

     

        try {
        
        
          var formData = new FormData();
          formData.append("username",username); 
          formData.append("key",key); 
       
    
          formData.append("id",updatedata.id);
          formData.append("photo",file);
          formData.append("alt",`وكيل ${updatedata.title} ${updatedata.title_} في اليمن`);
          
          axios.post(`https://admin.al-hubaishi.com/Api/edit_agent_img.php`, formData)
          .then(res => {
            
      



    if(res.data.success === true){
     
          stop()
          getData()

        toast.success(`${res.data.msg}`,{duration: 3000,},)
   
    
    
       

    }else{
      stop()
        toast.error(`${res.data.msg}`,{duration: 3000,},)
    }



   
        
          })
       } catch (error) { 
    stop()
    toast.error(`${error}`,{duration: 3000,},)
    throw error;}      
      
  
      




    



    }

          
  
  
      const [file,setFile] = useState("");
        
      const setimgfile = (e)=>{
        setFile(e.target.files[0])
      }
      
      
const inputFile = useRef(null);

const handleReset = () => {
  if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
  }
};
  
  
  
   
const [show, setShow] = React.useState(-1);  
  
  




const [fid, setFid] = React.useState(0);  
  

const [index, setIndex] = React.useState(0);  
  

      const [open, setOpen] = React.useState(false);
 
      const handleClose = () => {
        setIndex(0)
        setFid(0)
          setOpen(false);
      };
      
      const handleOpen = () => {
          setOpen(true);
      };
      
  
  




      const [open1, setOpen1] = React.useState(false);
 
      const handleClose1 = () => {
       
        setUpdateData({
          id:'',
          title:'',
          description:'',
          title_:'',
          description_:'',
          home:'0',
          category:'',
          src:'',
  })
  setShow(-1)
          setOpen1(false);
      };
      
      const handleOpen1 = () => {
          setOpen1(true);
      };
      
  



return (
 <div className="w-full h-screem pt-[80px] ">


<div className="mb-[10px]
">



  <form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={Add}>
 
 



 

<p className="title1"> الوكالات  </p>

<label  className="">
<input className="input" id="img" name="img" ref={inputFile} onChange={setimgfile} accept="image/*" type="file" placeholder="" required/>
        <span className=""> الصورة </span>
    </label> 

<div  dir='rtl' className="flex">


    <label  className="w-[50%] m-1 ">
    <select required placeholder="" value={data.category} name="category" id="category" onChange={onChangeValue} className="input">
            <option value=''></option>
            
                 
            <option  value='1'>مواد بناء</option>
                <option  value='2'>ادوات كهرباء</option>
                <option  value='3'>طلاء وبلاط</option>
                <option  value='4'>بلاستيك</option>
               
                
       
              
            </select>
            <span className=""> النوع </span>
    </label> 

    <label  className="w-[50%] m-1 ">
    <select required placeholder="" value={data.home} name="home" id="home"  onChange={onChangeValue} className="input">
            <option value=''></option>
            
            <option selected value='0'>لا</option>
            <option  value='1'>نعم</option>
       
              
            </select>
            <span className=""> الصقحة الرئيسية </span>
    </label> 

</div>

<hr />
    <p  className="message" > عربي </p>
    <label  className=" ">
        <input dir="rtl" maxLength={50} required className="input" id="title" name="title"  value={data.title} onChange={onChangeValue} placeholder=""  />
        <span className=""> الاسم </span>
    </label> 
   <label  className=" ">
        <textarea dir="rtl" required className="input" id="description" maxLength={2000} name="description" rows={7}  value={data.description} onChange={onChangeValue} placeholder=""  />
        <span className="mt-[140px] ">الوصف</span>
    </label> 

    
    <hr></hr>
    <p  className="message" > English </p>
    <label  className=" ">
        <input maxLength={50} required className="input" id="title_" name="title_"  value={data.title_} onChange={onChangeValue} placeholder=""  />
        <span className=""> Name </span>
    </label> 
 
   <label  className=" ">
        <textarea required className="input" id="description_" maxLength={2000} name="description_" rows={7}  value={data.description_} onChange={onChangeValue} placeholder=""  />
        <span className="mt-[140px] ">Description</span>
    </label> 
    <hr></hr>
    
    <label id="readonly" className=" ">
        <input dir="rtl" readOnly required className="input" id="alt" name="alt"  value={`وكيل ${data.title} ${data.title_} في اليمن`} onChange={onChangeValue} placeholder=""  />
        <span className=""> alt </span>
    </label> 
 
    
    <button className="submit">أضافة</button>

   
</form>








   

</div>



<hr></hr>
  <div  className= {`flex mt-2  `}>



<div dir="rtl" style={{placeContent:"center" , verticalAlign:'middle'}}  className=" m-auto flex max-w-[1000px] w-[90%] mb-2 p-2 rounded bg-gray-300  h-[40px] "> 






<div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[20%]">
   <div className="font-bold text-[15px]"> 
   <p style={{wordBreak:'break-word'}}>الصورة</p>
   </div>
 </div>   






 <div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[35%] ">
   <div className="font-bold text-[15px]"> 
   <p style={{wordBreak:'break-word'}}>الأسم</p>
   </div>
 </div>   






 <div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[20%] ">
   <div className="font-bold text-[15px]"> 
   <p style={{wordBreak:'break-word'}}>النشاط</p>
   </div>
 </div>   




 <div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[15%] ">
   <div className="font-bold text-[15px]"> 
   <p style={{}}>الرئيسية</p>
   </div>
 </div>   


 <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[10%] ">

  <p style={{wordBreak:'break-word'}}>...</p>

</div>




   </div>
</div>
<hr></hr>
  {agents.map((item,i) => 

<>
<div  className= {`flex mt-2  `}>



 <div dir="rtl" style={{placeContent:"center" , verticalAlign:'middle'}}  
 
 className=  {` m-auto flex max-w-[1000px] w-[90%] mb-2 p-2 rounded border-[2px] min-h-[70px]`}> 



 

  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[20%] ">
   <div className="flex ">
    <img className="m-auto rounded" src={`https://al-hubaishi.com/img/${item.src}`} alt='' height="70px" width="70px" fill="#ffffff" />
   </div>
  </div>


 



  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[35%] ">
    <div className="font-bold text-[15px] "> 
      <p style={{wordBreak:'break-word'}}>{item.title}</p>
      <p style={{wordBreak:'break-word'}}>{item.title_en}</p>
    </div>
  </div>




  <div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[30%]  ">
    <div className="font-bold text-[15px]"> 
    <p style={{wordBreak:'break-word'}}>{activity[Number(item.category)-1]}</p>
    </div>
  </div>   


  <div style={{placeContent:"center" , verticalAlign:'middle'}} className="w-[15%]  ">
    <div className="font-bold text-[15px]"> 
    <p style={{wordBreak:'break-word'}}>{Number(item.homescreen) > 0  ? "نعم" : 'لا'}</p>
    </div>
  </div>   


  <div style={{placeContent:"center" , verticalAlign:'middle'}} className=" w-[10%] ">

  <button onClick={()=>{



    if(show===i){
        setShow(-1)
  
   
    }else{


      setUpdateData({
        id:item.id,
 title:item.title,
 title_:item.title_en,
 description:item.description,
 description:item.description,
 description_:item.description_en,
 src:item.src,
 category:item.category,
 home:item.homescreen,
})

      setFid(item.id)
      setIndex(i)
        setShow(i)
    }
    
  }} >
            {show===i ?  <MdClose color="grey" size={30} />
            :<MdMoreVert color="grey" size={30} />}
            </button>
          { show===i ? <div className="dropdown">
  <div className="dropdown-content">

<button className="p-2 bg-[#dbdbdb] w-[120px] rounded hover:opacity-70 m-1" onClick={()=>{
handleOpen1()
}} >
        تعديل
          </button>
          <br></br>
          <button dir="rtl" className="p-2 bg-[#dbdbdb] w-[120px] rounded hover:opacity-70 m-1" onClick={()=>{
handleOpen()
}} >
      المنتجات   ({Number(item.items&&item.items.length)})
          </button>
          <br></br>
          <button className={`text-red-500 p-2 bg-[#dbdbdb] w-[120px] rounded hover:opacity-70 m-1`} onClick={()=>{



confirmAlert({
        title: 'Confirm to Submit',
        message:` هل أنت متأكد ، تريد حذف الوكالة  `,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              del(item.id)

}
          },
          {
            label: 'No',
            onClick: () => {  setShow(-1);}
          }
        ]
      });

}} >
       حذف
          </button>
</div>


  
</div>:''}
 
 </div>



    </div>


</div>

<hr />
</>

)}











<Modal isOpen={open} onClose={handleClose}>
<>

<h3 dir="rtl">المنتجات :</h3>
<ul   className='mt-5 list-disc ml-5 mr-5 space-y-5'>
{agents.length> 0 ? agents[index].items&&agents[index].items.map((product,index) => 


<li
style={{placeContent:"center" , verticalAlign:'middle'}}
className='flex text-black font-bold text-[14px] '>
<p  className="m-auto flex border-[2px] rounded p-4"><a href={`https://al-hubaishi.com/products/${product.file}`}>{index+1} {product.file}</a> &ensp;
<button  className="m-auto" onClick={()=>{
  
  
 setOpen(false)

confirmAlert({
        title: 'Confirm to Submit',
        message:` هل أنت متأكد ، تريد حذف منتج الوكالة  `,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {

              delete_(product.id)
}
          },
          {
            label: 'No',
            onClick: () => {  setShow(-1);}
          }
        ]
      });
  
  
  
  
  
}} ><MdDelete  className="m-auto" color="red" size={25}/></button> 

</p>


</li>


)
:''}

</ul>
<br />
<hr />
<br />



<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={Add_}>
 
 



 

 <p className="title1"> اضافة منتجات  </p>
 
 <label  className="">
 <input className="input" id="file" name="file" ref={inputFile} onChange={setimgfile} accept="" type="file" placeholder="" required/>
         <span className=""> الملف </span>
     </label> 
 
     <button className="submit">أضافة</button>

   
</form>
 

</>
</Modal>














<Modal isOpen={open1} onClose={handleClose1}>
<>

<div className="flex" >
  <img className="m-auto" src={`https://al-hubaishi.com/img/${updatedata.src}`} alt="" width={'200px'} />
</div>
<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={update_img}>

<p className="title1"> تعديل شعار الوكالة  </p>
 
 <label  className="">
 <input className="input" id="img" name="img" ref={inputFile} onChange={setimgfile} accept="image/*" type="file" placeholder="" required/>
         <span className=""> الصورة </span>
     </label> 


       
     
     <button className="submit">تحديث</button>

</form>
 
 <hr />

<form className="form1 w-[80%] max-w-[1000px]  m-auto"   onSubmit={update}>
 
<p className="title1"> تعديل الوكالة  </p>
 
 <div  dir='rtl' className="flex">
 
 
     <label  className="w-[50%] m-1 ">
     <select required placeholder="" value={updatedata.category} name="category" id="category" onChange={onChangeValue1} className="input">
             <option value=''></option>
             
                  
             <option  value='1'>مواد بناء</option>
                 <option  value='2'>ادوات كهرباء</option>
                 <option  value='3'>طلاء وبلاط</option>
                 <option  value='4'>بلاستيك</option>
                
                 
        
               
             </select>
             <span className=""> النوع </span>
     </label> 
 
     <label  className="w-[50%] m-1 ">
     <select required placeholder="" value={updatedata.home} name="home" id="home"  onChange={onChangeValue1} className="input">
             <option value=''></option>
             
             <option  value='0'>لا</option>
             <option  value='1'>نعم</option>
        
               
             </select>
             <span className=""> الصقحة الرئيسية </span>
     </label> 
 
 </div>
 
 <hr />
     <p  className="message" > عربي </p>
     <label  className=" ">
         <input dir="rtl" maxLength={50} required className="input" id="title" name="title"  value={updatedata.title} onChange={onChangeValue1} placeholder=""  />
         <span className=""> الاسم </span>
     </label> 
    <label  className=" ">
         <textarea dir="rtl" required className="input" id="description" maxLength={2000} name="description" rows={7}  value={updatedata.description} onChange={onChangeValue1} placeholder=""  />
         <span className="mt-[140px] ">الوصف</span>
     </label> 
 
     
     <hr></hr>
     <p  className="message" > English </p>
     <label  className=" ">
         <input maxLength={50} required className="input" id="title_" name="title_"  value={updatedata.title_} onChange={onChangeValue1} placeholder=""  />
         <span className=""> Name </span>
     </label> 
  
    <label  className=" ">
         <textarea required className="input" id="description_" maxLength={2000} name="description_" rows={7}  value={updatedata.description_} onChange={onChangeValue1} placeholder=""  />
         <span className="mt-[140px] ">Description</span>
     </label> 
     <hr></hr>
     
     <label id="readonly" className=" ">
         <input dir="rtl" readOnly required className="input" id="alt" name="alt"  value={`وكيل ${updatedata.title} ${updatedata.title_} في اليمن`} onChange={onChangeValue1} placeholder=""  />
         <span className=""> alt </span>
     </label> 
  
     
     <button className="submit">تعديل</button>
 
    
 </form>
 
 
 
 
 

</>
</Modal>




    </div>






);
};

export default Agencies;
