import React, { forwardRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";



const Home = ({loading, stop}) => {



  const auth = useAuthUser()

  const key = auth().key; 
    
  const username = auth().username;

  const products =useSelector((state) => state.cart.data);
  
  
        
  


return (
 <div className="w-full h-screem pt-[80px] ">


<div className="flex font-bold">

<div dir="rtl"  className="p-1 m-auto max-w-[1000px] grid grid-cols-[repeat(auto-fill,minmax(220px,1fr))] gap-4 w-full  ">

{products&&products.length > 0 ? 
      
      <>
      {Number(products&&products[0].read ) > 0 ?
      
  
<div class="flex flex-col max-w-[200px] min-w-[200px] bg-[#eaeaea] m-auto rounded-3xl">
  <div class="px-6 py-8 sm:p-10 sm:pb-6">
    <div class="grid items-center justify-center w-full grid-cols-1 text-left">
      <div>
        <h2
          class="text-lg font-medium tracking-tighter text-center text-black lg:text-3xl "
        >
          المقدمة
        </h2>
		<p class="mt-2 text-sm text-center text-gray-500 ">  تغيير بيانات مقدمة الصفحة </p>
      </div>
    
    </div>
  </div>
  <div class="flex px-6 pb-8 sm:px-8">
    <Link
    to={"about"}
      aria-describedby="tier-company"
      class="flex items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-full nline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none focus-visible:outline-black text-sm focus-visible:ring-black"
	 
    >
دخول    </Link>
  </div>
</div>
 
     
     :'' }
     {Number(products&&products[1].read ) > 0 ?


      <div class="flex flex-col max-w-[200px] min-w-[200px] bg-[#eaeaea] m-auto rounded-3xl">
  <div class="px-6 py-8 sm:p-10 sm:pb-6">
    <div class="grid items-center justify-center w-full grid-cols-1 text-left">
      <div>
        <h2
          class="text-lg font-medium tracking-tighter text-center text-black lg:text-3xl"
        >
         الصور
        </h2>
		<p class="mt-2 text-sm text-center text-gray-500 ">  تعديل واضافة وحذف الصور   </p>
      </div>
    
    </div>
  </div>
  <div class="flex px-6 pb-8 sm:px-8">
    <Link
    to={"Images"}
      aria-describedby="tier-company"
      class="flex items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-full nline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none focus-visible:outline-black text-sm focus-visible:ring-black"
	 
    >
دخول    </Link>
  </div>
</div>




     :'' }


     {Number(products&&products[2].read ) > 0 ?
     
<div class="flex flex-col max-w-[200px] min-w-[200px] bg-[#eaeaea] m-auto rounded-3xl">
  <div class="px-6 py-8 sm:p-10 sm:pb-6">
    <div class="grid items-center justify-center w-full grid-cols-1 text-left">
      <div>
        <h2
          class="text-lg font-medium tracking-tighter text-center text-black lg:text-3xl"
        >
          عنا
        </h2>
		<p class="mt-2 text-sm text-center text-gray-500 ">  ادارة بيانات صفخة عنا </p>
      </div>
    
    </div>
  </div>
  <div class="flex px-6 pb-8 sm:px-8">
    <Link
    to={"About_us"}
      aria-describedby="tier-company"
      class="flex items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-full nline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none focus-visible:outline-black text-sm focus-visible:ring-black"
	 
    >
دخول    </Link>
  </div>
</div>

     
     :'' }
     {Number(products&&products[3].read ) > 0 ?
      
         
<div class="flex flex-col max-w-[200px] min-w-[200px] bg-[#eaeaea] m-auto rounded-3xl">
  <div class="px-6 py-8 sm:p-10 sm:pb-6">
    <div class="grid items-center justify-center w-full grid-cols-1 text-left">
      <div>
        <h2
          class="text-lg font-medium tracking-tighter text-center text-black lg:text-3xl"
        >
          النشاطات
        </h2>
		<p class="mt-2 text-sm text-center text-gray-500 ">  تغيير بيانات النشاطات </p>
      </div>
    
    </div>
  </div>
  <div class="flex px-6 pb-8 sm:px-8">
    <Link
    to={"Activities"}
      aria-describedby="tier-company"
      class="flex items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-full nline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none focus-visible:outline-black text-sm focus-visible:ring-black"
	 
    >
دخول    </Link>
  </div>
</div>

      

     :'' }
  
     
     {Number(products&&products[4].read ) > 0 ?
    
    
            
<div class="flex flex-col max-w-[200px] min-w-[200px] bg-[#eaeaea] m-auto rounded-3xl">
  <div class="px-6 py-8 sm:p-10 sm:pb-6">
    <div class="grid items-center justify-center w-full grid-cols-1 text-left">
      <div>
        <h2
          class="text-lg font-medium tracking-tighter text-center text-black lg:text-3xl"
        >
      الوكالات
        </h2>
		<p class="mt-2 text-sm text-center text-gray-500 ">   تعديل واضافة وحذف الوكالات   </p>
      </div>
    
    </div>
  </div>
  <div class="flex px-6 pb-8 sm:px-8">
    <Link
    to={"Agencies"}
      aria-describedby="tier-company"
      class="flex items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-full nline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none focus-visible:outline-black text-sm focus-visible:ring-black"
	 
    >
دخول    </Link>
  </div>
</div>

    
    
    
    
      
     
     :'' } 

     {Number(products&&products[5].read ) > 0 ?
      
      
      
              
<div class="flex flex-col max-w-[200px] min-w-[200px] bg-[#eaeaea] m-auto rounded-3xl">
  <div class="px-6 py-8 sm:p-10 sm:pb-6">
    <div class="grid items-center justify-center w-full grid-cols-1 text-left">
      <div>
        <h2
          class="text-lg font-medium tracking-tighter text-center text-black lg:text-3xl"
        >
      الفروع
        </h2>
		<p class="mt-2 text-sm text-center text-gray-500 ">  ادارة وتعديل المناطق والفروع </p>
      </div>
    
    </div>
  </div>
  <div class="flex px-6 pb-8 sm:px-8">
    <Link
    to={"Branches"}
      aria-describedby="tier-company"
      class="flex items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-full nline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none focus-visible:outline-black text-sm focus-visible:ring-black"
	 
    >
دخول    </Link>
  </div>
</div>

      
      

      
     
     :'' } 
     </>
     :''}




</div>
</div>
  </div>
);
};

export default Home;
